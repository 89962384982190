<template>
  <div>
  <div>
    <div class="row add-padding" >
      <div  class="col-lg-3 col-6 product-box" :id="`catalog${product._id}`"  v-for="(product) in catalog" :key="product._id"   >
          <div class="">
            <div @click="$router.push({ path:`/${currentCompanyCatalog}/catalog-detail/${product._id}`})" class="product-img"  >
              <img loading="lazy" v-if="!product.error" :src="server+'/'+product.img" @error="product.error=true">
              <img v-else src="../../../assets/icons/noProd.svg" >
            </div>
            <div class="product-add">
              <h2 class="long-text tool-tip"  style="overflow: hidden;text-overflow: ellipsis; margin-bottom:10px;"
                  data-toggle="tooltip" data-placement="top" :title="product.name"
              >{{product.name}}</h2>

              <span v-if="checkDates(product.promoStart, product.promoEnd)" :class="'discountPrice'">
                {{ product.promoPrice }} {{ catalog_settings.currency }}
              </span>

              <span :class="{lineThrough: checkDates(product.promoStart, product.promoEnd)}">
                {{ product.price }} {{ catalog_settings.currency }}
              </span>
            </div>
            <button 
              class="add-to-card" 
              :class="{ 'not-available': product.quantity <= 0 }"
              @click="product.quantity > 0 ? addToCart(product._id) : null"
              :disabled="product.quantity <= 0"
            >
              {{ product.quantity === 0 || product.quantity === -1 ? 'Not Available' : 'Add to cart' }}
            </button>
          </div>
        </div>
    </div>
  </div>
</div>

</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import { Tooltip } from 'bootstrap'

export default {
name: "ClientCatalogItem",
  props:['catalog'],
  components:{

  },

  data(){
    return{
      today:new Date(),
      catalogList:[],
      page:1,
      noResult:false,
      message:""


    }
  },
  computed:{

    userDiscountStatus(){
      return this.$store.getters['Client/getUserDiscountStatus'];
    },
    company_url_basket(){
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    server(){
      return this.$server;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
  },
  methods:{

    checkDates(start,end){
      if(!start || !start){
        return false
      }
      let itsPromo = false;
      let startDate = new Date(start);
      let endDate = new Date(end);
      if(startDate<=this.today && endDate>=this.today){
        itsPromo = true;
      }
      return itsPromo;
    },
    addToCart(id){
      const order = this.catalog.filter((el)=>el._id === id);
      if (!order || !order[0]) return;

      if (order[0].hasMultipleTypes && (!this.selectedSize || !this.selectedSize._id)) {
        const backdrop = document.createElement('div');
        backdrop.style.position = 'fixed';
        backdrop.style.top = '0';
        backdrop.style.left = '0';
        backdrop.style.width = '100%';
        backdrop.style.height = '100%';
        backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        backdrop.style.zIndex = '1001';
        document.body.appendChild(backdrop);

        Swal.fire({
          // icon: 'warning',
          title: '<div style="color: white; font-size: 18px; margin-left: 45px;">Warning</div>',
          html: `
            <div style="margin-top: -30px;">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 29.7057C7.62214 29.7057 1.29297 23.3766 1.29297 15.4974C1.29297 7.61823 7.62214 1.28906 15.5013 1.28906C23.3805 1.28906 29.7096 7.61823 29.7096 15.4974C29.7096 23.3766 23.3805 29.7057 15.5013 29.7057Z" fill="white"/>
                
                <path d="M15.5013 3.8724C9.04297 3.8724 3.8763 9.03906 3.8763 15.4974C3.8763 21.9557 9.04297 27.1224 15.5013 27.1224C21.9596 27.1224 27.1263 21.9557 27.1263 15.4974C27.1263 9.03906 21.9596 3.8724 15.5013 3.8724Z" fill="#fff"/>
                
                <path d="M15.5013 16.7891C14.7263 16.7891 14.2096 16.2724 14.2096 15.4974V10.3307C14.2096 9.55573 14.7263 9.03906 15.5013 9.03906C16.2763 9.03906 16.793 9.55573 16.793 10.3307V15.4974C16.793 16.2724 16.2763 16.7891 15.5013 16.7891Z" fill="#FDB62E"/>
                
                <path d="M15.5013 21.9557C15.1138 21.9557 14.8555 21.8266 14.5971 21.5682C14.3388 21.3099 14.2096 21.0516 14.2096 20.6641C14.2096 20.5349 14.2096 20.2766 14.3388 20.1474C14.468 20.0182 14.468 19.8891 14.5971 19.7599C14.9846 19.3724 15.5013 19.2432 16.018 19.5016C16.1471 19.5016 16.1471 19.5016 16.2763 19.6307C16.2763 19.6307 16.4055 19.7599 16.5346 19.7599C16.6638 19.8891 16.793 20.0182 16.793 20.1474C16.793 20.2766 16.793 20.5349 16.793 20.6641C16.793 20.7932 16.793 21.0516 16.6638 21.1807C16.5346 21.3099 16.5346 21.4391 16.4055 21.5682C16.1471 21.8266 15.8888 21.9557 15.5013 21.9557Z" fill="#FDB62E"/>
              </svg>
            </div>
            <div style="color: white; font-size: 14px; margin-left: 45px; margin-top: -10px;">Please select size</div>
            <style>
              .swal2-popup {
                border-radius: 13px !important;
                z-index: 9999 !important;
                box-shadow: none !important;
              }
              .swal2-close {
                color: white !important;
                font-size: 25px !important;
              }
              .swal2-icon {
                color: white !important;
                font-size: 12px !important;
              }
            </style>
          `,
          position: 'top-end',
          background: '#FDB62E',
          width: '310px',
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          toast: true,
          customClass: {
            popup: 'success-popup warning-popup',
            content: 'success-content',
            title: 'success-title',
            header: 'success-header',
            image: 'success-img'
          },
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          },
          willClose: () => {
            backdrop.classList.add('animate__animated', 'animate__fadeOut');
            backdrop.addEventListener('animationend', () => {
              document.body.removeChild(backdrop);
            });
          }
        });

        return;
      }

      if (order[0].colors.length > 0 && !this.picked_color) {
        const backdrop = document.createElement('div');
        backdrop.style.position = 'fixed';
        backdrop.style.top = '0';
        backdrop.style.left = '0';
        backdrop.style.width = '100%';
        backdrop.style.height = '100%';
        backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        backdrop.style.zIndex = '1001';
        document.body.appendChild(backdrop);

        Swal.fire({
          // icon: 'warning',
          title: '<div style="color: white; font-size: 18px; margin-left: 45px;">Warning</div>',
          html: `
            <div style="margin-top: -30px;">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 29.7057C7.62214 29.7057 1.29297 23.3766 1.29297 15.4974C1.29297 7.61823 7.62214 1.28906 15.5013 1.28906C23.3805 1.28906 29.7096 7.61823 29.7096 15.4974C29.7096 23.3766 23.3805 29.7057 15.5013 29.7057Z" fill="white"/>
                
                <path d="M15.5013 3.8724C9.04297 3.8724 3.8763 9.03906 3.8763 15.4974C3.8763 21.9557 9.04297 27.1224 15.5013 27.1224C21.9596 27.1224 27.1263 21.9557 27.1263 15.4974C27.1263 9.03906 21.9596 3.8724 15.5013 3.8724Z" fill="#fff"/>
                
                <path d="M15.5013 16.7891C14.7263 16.7891 14.2096 16.2724 14.2096 15.4974V10.3307C14.2096 9.55573 14.7263 9.03906 15.5013 9.03906C16.2763 9.03906 16.793 9.55573 16.793 10.3307V15.4974C16.793 16.2724 16.2763 16.7891 15.5013 16.7891Z" fill="#FDB62E"/>
                
                <path d="M15.5013 21.9557C15.1138 21.9557 14.8555 21.8266 14.5971 21.5682C14.3388 21.3099 14.2096 21.0516 14.2096 20.6641C14.2096 20.5349 14.2096 20.2766 14.3388 20.1474C14.468 20.0182 14.468 19.8891 14.5971 19.7599C14.9846 19.3724 15.5013 19.2432 16.018 19.5016C16.1471 19.5016 16.1471 19.5016 16.2763 19.6307C16.2763 19.6307 16.4055 19.7599 16.5346 19.7599C16.6638 19.8891 16.793 20.0182 16.793 20.1474C16.793 20.2766 16.793 20.5349 16.793 20.6641C16.793 20.7932 16.793 21.0516 16.6638 21.1807C16.5346 21.3099 16.5346 21.4391 16.4055 21.5682C16.1471 21.8266 15.8888 21.9557 15.5013 21.9557Z" fill="#FDB62E"/>
              </svg>
            </div>
            <div style="color: white; font-size: 14px; margin-left: 45px; margin-top: -10px;">Please select color</div>
            <style>
              .swal2-popup {
                border-radius: 13px !important;
                z-index: 9999 !important;
                box-shadow: none !important;
              }
              .swal2-close {
                color: white !important;
                font-size: 25px !important;
              }
              .swal2-icon {
                color: white !important;
                font-size: 12px !important;
              }
            </style>
          `,
          position: 'top-end',
          background: '#FDB62E',
          width: '310px',
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          toast: true,
          customClass: {
            popup: 'success-popup warning-popup',
            content: 'success-content',
            title: 'success-title',
            header: 'success-header',
            image: 'success-img'
          },
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          },
          willClose: () => {
            backdrop.classList.add('animate__animated', 'animate__fadeOut');
            backdrop.addEventListener('animationend', () => {
              document.body.removeChild(backdrop);
            });
          }
        });

        return;
      }

      if (order[0].hasMultipleTypes) {
        $('#selectSizeModalClientCatalogItem').modal('show');
        return;
      }

      let that = this;
      let cart_object = {
        size: {},
        client_status_discount: this.userDiscountStatus.discount_percentage || 0,
        product: order[0],
        isDiscounted: false,
        quantity: 1,
        promocode: '',
        discount_percent: 0,
        discount_percent_sum: 0,
        discount_sum: 0,
        current_price: 0,
      };

      // Promo price check
      let current_price = order[0].price || 0;
      let old_price = order[0].price || 0;
      
      if (this.checkDates(cart_object.product.promoStart, cart_object.product.promoEnd)) {
        current_price = order[0].promoPrice || 0;
        let discount_sum = old_price - current_price;
        cart_object.discount_sum = discount_sum > 0 ? discount_sum : 0;
        cart_object.isDiscounted = true;
      } else if (cart_object.client_status_discount > 0) {
        let disc = (current_price * (cart_object.client_status_discount / 100)).toFixed(2);
        current_price = (current_price - disc).toFixed(2);
        cart_object.discount_percent_sum = disc;
      }

      cart_object.current_price = current_price;
      this.$store.dispatch('Orders/addToCart', cart_object);
      this.$store.dispatch('Orders/setCompany_url_basket', that.$route.params.bekon);

      let version = new Date();
      this.$store.dispatch("Orders/setVersion", version);
    },
      selectProduct(id){
        this.$router.push({ path: `/${this.currentCompanyCatalog}/catalog-detail/${id}`});
      },
    },
  mounted(){
    this.$nextTick(() => {
      // Инициализация tooltip для всех элементов с атрибутом data-bs-toggle="tooltip"
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
        new Tooltip(el);
      });
    });
  },
  watch: {
    catalog: {
      handler() {
        this.$nextTick(() => {
          document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
            new Tooltip(el);
          });
        });
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.add-padding{
  padding-right: 15px;
}

.long-text {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  /* border: 1px solid red */
}

.product-img {
  margin-bottom: 16px;
  height:176px;
  /* aspect-ratio: 1/1; */
}
.lineThrough {
  text-decoration: line-through;
  font-size: 11px !important;
  font-weight: 600;
}
.discountPrice {
  color: #BF2C2C;
  font-size: 16px;
  font-weight: 700;
  margin-right: 8px;
}
.product-img img{
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 15px;

  /* transition: all .3s ease-in-out; */
}
.product-add{
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.add-to-card{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border:none;
  background: #616CF5;
  border: 1px solid #616CF5;
  border-radius: 100px;
  width: 140px;
  height: 28px;
}
.add-to-card:hover{
  background: #616CF5D9;
  /* border: 1px solid #616CF5; */
  transition:.3s;
}
.not-available {
  cursor: not-allowed;
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
  font-size: 12px !important;
  height: 28px;
}
@media (max-width: 1200px) {
  .not-available {
    height: 28px;
  }
}
.not-available:hover{
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
}
.product-box{
  text-align: center;
  cursor:pointer;
  padding:15px;
  box-sizing: border-box;
  border: 0.5px solid transparent;
  padding-bottom: 20px;
  /* border: 1px solid red; */
}
.product-box:hover{
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 15px;
  transition:0.3s;
  /*transform:scale(1.1,1.1)*/
}

.product-add  span{
  font-size: 16px;
}
.product-add h2{
  margin-bottom: 5px;
}
.product-add h2, .product-add h3{
  font-size: 14px;
}
.product-add h3{
  font-weight: normal;
  color:#b0b0b0;
  margin: 5px 0;
}
@media(max-width:576px){
  .product-box:hover{
    border:none;
  }
  .product-box{
    padding-right: 0;
  }
}

@media(max-width:380px){
  .product-img img{
    height: 170px;
  }
  .product-img{
    margin-bottom: 0;
  }
  .add-to-card{
    padding:0.3rem 30px;
  }
}
@media(max-width:330px){
  .product-img img{
    height: 138px;
  }

}
</style>