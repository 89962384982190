<template>
<div class="container" style="padding-bottom: 20px; margin-top: 80px">
    <h1 class="product-title">Catalog</h1>
    <div class="row catalog">
        <div id="categories" class="col-lg-3 col-md-4" style="padding-top: 15px;">
          <div class="catalog-left">
      
            <div class="selectWrapper" style="display:flex;flex-direction: column;position: relative">
              <div @click="showSortDrop=!showSortDrop" style="display: flex;justify-content: space-between">
                <div>{{ sortOptions[sortBy] || "Choose sort." }}</div>
                <div>
                  <img v-bind:style="[showSortDrop? {transform:'rotate(180deg)'}:'']" src="../../../assets/img/select_arrow.svg">
                </div>
              </div>

              <div class="d-flexx sortDropDown" v-if="showSortDrop">
                <div v-for="(label, key) in sortOptions" :key="key" @click="setSort(key)">{{ label }}</div>
              </div>
            </div>
            <hr/>
            <h3 class="price">Price:</h3>
            <!-- <div class="d-flex mb-5">
              <div style="width:50%" class="mr-3">
                <label class="label-client">From</label>
                <input class="client-input" v-model="from" style="width:100%">
              </div>
              <div style="width:50%">
                <label class="label-client">To</label>
                <input class="client-input" v-model="to" style="width:100%">
              </div>
            </div> -->
            <input type="text" id="range-slider" name="example_name" value="" class="d-none"/>
            <div class="line"></div>

          <ul class="list-group">
            <transition-group name="category-fade" tag="div">
              <div class="mobile-header">
                <h3 style="margin-top: -4px;" class="filter-category">Categories:</h3>
              </div>
              <li v-for="category in listCategory.filter(cat => !cat.parent || expandedCategories.includes(cat.parent))" 
                  :key="category._id"
                  class="catalog-list"
                  :class="{ active: category._id === selectedCategory }"
                  @click="handleCategoryClick(category)">
                <div class="d-flex align-items-center justify-between flexing"
                  :style="{ paddingLeft: `${0 + category.level * 20}px`, width: `100%`, fontSize: category.level === 0 ? '16px' : '15px', fontWeight: category.level === 0 ? '600' : '400' }">
                  {{ category.name }}
                  <button v-if="hasChildren(category)" class="btn btn-link p-0 me-2 no-outline">
                    <span v-if="expandedCategories.includes(category._id)">
                      <img src="../../../assets/clients/accordeon2.svg"/>
                    </span>
                    <span v-else>
                      <img src="../../../assets/clients/accordeon.svg"/>
                    </span>
                  </button>
                </div>
              </li>
            </transition-group>
          </ul>

          </div>
          
          <div class="mobile-btns">
            <button  class="save" @click="showFilterCategory('category')" ><img class="list-icon" src="../../../assets/icons/list.svg"><span>Select categories</span></button>

            <div class="showCategory">
              <div class="mobile-header">
                <img @click="removeFilterCategory('category')" class="close-nav" src="../../../assets/icons/xBlack.svg">
                <h3 style="margin-top: -4px;" class="filter-category">Categories</h3>
              </div>
              <div style="height:calc(100% - 82px); overflow-y:auto; padding-bottom: 20px;">
                <ul class="list-group">
                  <transition-group name="category-fade" tag="div">
                    <li v-for="category in listCategory.filter(cat => !cat.parent || expandedCategories.includes(cat.parent))" 
                        :key="category._id"
                        class="catalog-list"
                        :class="{ active: category._id === selectedCategory }"
                        @click="handleCategoryClick(category)">
                      <div class="d-flex align-items-center justify-between flexing" 
                        :style="{ paddingLeft: `${0 + category.level * 20}px`, width: `100%`, fontSize: category.level === 0 ? '16px' : '15px', fontWeight: category.level === 0 ? '600' : '400' }">
                        {{ category.name }}
                        <button v-if="hasChildren(category)" class="btn btn-link p-0 me-2">
                          <span v-if="expandedCategories.includes(category._id)">
                            <img src="../../../assets/clients/accordeon2.svg"/>
                          </span>
                          <span v-else>
                            <img src="../../../assets/clients/accordeon.svg"/>
                          </span>
                        </button>
                      </div>
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>

            <div class="d-flex mb-3" style="position: relative;">
              <button style="position: relative;" class="app-buttons-item" @click="showFilterCategory('filter')"><img src="../../../assets/icons/filter-btn.svg"><span>Filters</span></button>
              
              <div class="selectWrapper" style="display:flex;flex-direction: column;position: relative">
                <div @click="showSortDrop=!showSortDrop" style="display: flex;justify-content: space-between">
                  <div>{{ sortOptions[sortBy] || "Choose sort." }}</div>
                  <div>
                    <img v-bind:style="[showSortDrop? {transform:'rotate(180deg)'}:'']" src="../../../assets/img/select_arrow.svg">
                  </div>
                </div>

                <div class="d-flexx sortDropDown" v-if="showSortDrop">
                  <div v-for="(label, key) in sortOptions" :key="key" @click="setSort(key)">{{ label }}</div>
                </div>
              </div>
            </div>
            <div class="showFilter">
              <div class="mobile-header d-flex justify-content-between align-items-center" >
                <h3 class="filter-category">Filter</h3>
                <img @click="removeFilterCategory('filter')" class="close-nav" src="../../../assets/icons/xBlack.svg">
              </div>
              <div class="d-flex mb-5">
                <div style="width:40%" class="mr-3">
                  <label class="label-client">From</label>
                  <input class="client-input" v-model="from" style="width:100%">
                </div>
                <div style="width:40%">
                  <label class="label-client">To</label>
                  <input class="client-input" v-model="to" style="width:100%">
                </div>
              </div>
              <input class="d-none" type="text" id="range-slider2" name="example_name" value="" />
            </div>
            <div class="category-key">
              Category: 
              <span class="category-value">
                {{ 
                  (() => {
                    const category = listCategory.find((item) => item._id === selectedCategory);
                    return category?.name || 'No Category';
                  })()
                }}
              </span>
            </div>
          </div>
        </div>
      
        <div  id="products" class="pt-0 col-lg-9 col-md-8">
      
          <div v-if="filteredList.length !== 0 && searchText !=='' && searchStatus">
            <div style="display: flex;justify-content: center;align-items: center;">
              Results for: {{searchText}}
            </div>
          </div>
          
          <div v-if="spinner" class="row" >
            <div class="col-md-3 col-sm-6 mb-4" v-for="n in 12" :key="n">
              <MainSkeleton />
            </div>
          </div>
          <div v-else-if="filteredList.length === 0 && !spinner" class="box">
            <div  class="noProd">
              <img src="../../../assets/icons/noProd2.svg" />
              <p>No products</p>
            </div>
          </div>
          <div v-else>
            <ClientCatalogItemPage v-bind:catalog="filteredList"/>
          </div>
      
          <div v-if="filteredList.length !== 0" style="display: flex; height: 50px; width: 100%; justify-content: center; align-items: center; padding-top: 80px;">
            <div class="pagination">
              <!-- Стрелка назад -->
              <div class="arrow" @click="changePage(currentPage - 1)" style="cursor: pointer;">
                <svg class="arrow-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1251 14.6106L6.50672 9.99229C5.9613 9.44687 5.9613 8.55438 6.50672 8.00896L11.1251 3.39062" stroke="#747474" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="#fff"/>
                </svg>
              </div>

              <!-- Пагинация -->
              <div v-for="page in displayedPages" :key="page"
                  class="paginationItem" 
                  :class="{ activePage: currentPage === page }"
                  @click="changePage(page)" 
                  style="cursor: pointer;">
                {{ page }}
              </div>

              <!-- Последняя страница -->
              <div style="display: flex; align-items: center" v-if="numberOfPagesArray.length > maxVisiblePages && currentPage !== numberOfPagesArray.length">
                ...
                <div class="paginationItem"
                    @click="changePage(numberOfPagesArray.length)" 
                    style="cursor: pointer;">
                  {{ numberOfPagesArray.length }}
                </div>
              </div>

              <!-- Стрелка вперед -->
              <div class="arrow" @click="changePage(currentPage + 1)" style="cursor: pointer;">
                <svg class="arrow-icon" width="7" height="15" viewBox="0 0 7 15" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1.57812L5.59317 6.51752C6.13561 7.10085 6.13561 8.0554 5.59317 8.63873L1 13.5781" stroke="gray" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>

        </div>
      
      </div>
</div>
</template>

<script>
import $ from 'jquery';
import { useRoute, useRouter } from 'vue-router';
import ClientCatalogItemPage from "@/client/components/ClientCatalog/ClientCatalogItemPage";
import MainSkeleton from '../../../components/skeleton/MainSkeleton.vue';
import { debounce } from 'lodash';
const _ = require('lodash');
export default {
name: "Catalog",
  components:{
    ClientCatalogItemPage,
    MainSkeleton
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  data(){
    return{
      showSortDrop:false,
      sortBy:"Choose sort.",
      sortOptions: {
        ascendingName: "Name A-Z",
        descendingName: "Name Z-A",
        ascendingPrice: "Price 0-100",
        descendingPrice: "Price 100-0",
      },
      searchStatus:false,
      searchText:"",
      spinner:true,
      catalog:[],
      listCategory:[],
      filtered: 'all',
      selectedCategory:"all",
      expandedCategories: [],
      from:0,
      to:1000,
      showCategory:'All',
      currentPage:1,
      numberOfPagesArray:[],
    }
  },
  computed:{
    maxVisiblePages() {
      return window.innerWidth < 768 ? 5 : 10; // 5 страниц на мобильных, 10 на десктопе
    },
    displayedPages() {
      const totalPages = this.numberOfPagesArray.length;
      const maxPages = this.maxVisiblePages;
      
      if (totalPages <= maxPages) return this.numberOfPagesArray;
      
      const middleIndex = Math.floor(maxPages / 2);
      let startPage = Math.max(1, this.currentPage - middleIndex);
      let endPage = Math.min(totalPages, startPage + maxPages - 1);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxPages + 1);
      }

      return this.numberOfPagesArray.slice(startPage - 1, endPage);
    },
    filteredList: function(){
      return  this.catalog
        // .filter(product => {
        //   if(this.filtered!=='all'){
        //     return product.category===this.filtered;
        //   }else{
        //     return product;
        //   }
        // })
        // .filter((product)=>{
        //   return product.price >= this.from && product.price <= this.to;
        // })
        // .filter((product)=>{
        //   return product.quantity > 0;
        // })
        // .filter((product)=>{
        //   return product.active === true;
        // })
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
  },
  methods: {
    setSort(sortKey) {
      this.sortBy = sortKey;
      this.getProducts();
      this.showSortDrop = false;
    },
    changePage(page) {
      const maxPage = this.numberOfPagesArray[this.numberOfPagesArray.length - 1];

      if (page >= 1 && page <= maxPage && this.currentPage !== page) {
        this.currentPage = page;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.getProducts();
      }
    },
    catalogSearch(){
      // this.selectedCategory = "all";
      this.currentPage = 1;
      this.getProducts();
      this.searchStatus = true;
    },
    setPage(page){
      console.log(page)
      this.currentPage = page;
      localStorage.setItem('currentPage', page);
      this.getProducts();
    },
    displayFiltered(id){
      this.filtered = id
      this.listCategory.map(i=>{
        if(i._id == id){
          this.showCategory = i.name
        }
      })
      $('.showCategory').removeClass('active')
      
      $('.backdrop-filter').removeClass('active')
      $('body').css({'overflow':'auto'})
    },
    showFilterCategory(item){
      console.log("showFilterCategory");
      if(item==='category'){
        $('.showCategory').addClass('active')
      }
      else{
        $('.showFilter').addClass('active')
      }
      $('.backdrop-filter').addClass('active')
      $('body').css({'overflow':'hidden'})
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },
    
    removeFilterCategory(item){
      console.log("removeFilterCategory");
      if(item === 'category'){
        $('.showCategory').removeClass('active')
      }
      else{
        $('.showFilter').removeClass('active')
      }
      $('body').css({'overflow':'auto'})
      $('.backdrop-filter').removeClass('active')
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    getRangeValues() {
      const slider = $("#range-slider").data("ionRangeSlider");
      this.from = slider.result.from;
      this.to = slider.result.to;
      console.log(`getRangeValues call ${slider.result.from} ${slider.result.to}`)
    },
    async  getProducts(){
      // console.log("getProducts call");
      this.spinner = true;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog},
        params: {
          "page":this.currentPage,
          "categoryId":this.selectedCategory,
          "min":this.from,
          "max":this.to,
          "searchText":this.$route.query.searchText || this.searchText || "",
          "sortBy":this.sortBy,
          "limit": 12
        },
      }
      await this.axios.get(this.url('getClientProducts'),options)
      .then((response) => {
        this.catalog = response.data.objects;
        console.log("catalog", this.catalog)
        this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
        console.log("numberOfPagesArray", this.numberOfPagesArray)
        setTimeout(() => {
          this.spinner = false;
        }, 500)
      })
    },
    rangeSlider() {
      // console.log("range slider call");
      $("#range-slider").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        postfix: " $",
        onChange: (data) => {
          console.log(`rangeSlider on change ${data.from}`)
          this.from = data.from;
          this.to = data.to
        }
      });
      $("#range-slider2").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        postfix: " $",
        onChange: (data) => {
          this.from = data.from;
          this.to = data.to
        }
      });
    },
    async  getProductsForPrice(){
      //yes its a copy of a function
      // console.log("getProductsForPrice_call");
      // let that = this;
      // const options = {
      //   headers: {"x-client-url": this.currentCompanyCatalog},
      //   params: {
      //     "page":this.currentPage,
      //     "categoryId":this.selectedCategory,
      //     "min":this.from,
      //     "max":this.to,
      //   },
      // }
      // await this.axios.get(this.url('getClientProducts'),options)
      // .then((response) => {
      //   // this.catalog = response.data.objects;
      //   // this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
      //   // this.spinner = false;
      //   this.from = response.data.minPrice;
      //   this.to = response.data.maxPrice;
      //   let instance = $("#range-slider").data("ionRangeSlider");
      //   instance.update({
      //     from: that.from,
      //     to:that.to,
      //     max:that.to,
      //   });
      //   let instance2 = $("#range-slider2").data("ionRangeSlider");
      //   instance2.update({
      //     from: that.from,
      //     to:that.to,
      //     max:that.to,
      //   });
      // })
    },
    handleCategoryClick(category) {
      if (this.expandedCategories.includes(category._id)) {
        this.setCategory('all');
      }else{
        this.setCategory(category._id);
      }
      this.toggleCategory(category);
    },
    setCategory(id) {
      this.selectedCategory = id;
      this.currentPage = 1;
      localStorage.setItem('currentCategory', id);
      this.getProducts();
      this.$router.push({ query: { ...this.$route.query, categoryId: id } });
    },
    toggleCategory(category) {
      if (!category.parent) {
        // Если родитель уже открыт, закрываем его и всех потомков
        if (this.expandedCategories.includes(category._id)) {
          this.expandedCategories = this.expandedCategories.filter(id => !this.isDescendant(id, category._id));
          this.expandedCategories = this.expandedCategories.filter(id => id !== category._id);
          this.setCategory('all')
        } else {
          // Закрываем другие родительские категории и открываем текущую
          this.expandedCategories = [category._id];
        }
      } else {
        // Переключаем только текущую дочернюю категорию
        const index = this.expandedCategories.indexOf(category._id);
        if (index > -1) {
          this.expandedCategories.splice(index, 1);
        } else {
          this.expandedCategories.push(category._id);
        }
      }
    },

    isDescendant(categoryId, parentId) {
      const queue = [parentId]; // Очередь для поиска всех потомков
      while (queue.length) {
        const currentParent = queue.shift();
        const children = this.listCategory.filter(cat => cat.parent === currentParent);
        if (children.some(child => child._id === categoryId)) return true;
        queue.push(...children.map(child => child._id));
      }
      return false;
    },

    hasChildren(category) {
      return this.listCategory.some(cat => cat.parent === category._id);
    },
    getChildren(parentId) {
      return this.listCategory.filter(cat => cat.parent === parentId);
    },
    processCategories(categories) {
      const categoryMap = {};
      const processedCategories = [];

      categories.forEach(category => {
        categoryMap[category._id] = { ...category, level: 0, children: [] };
      });

      categories.forEach(category => {
        if (category.parent) {
          categoryMap[category.parent].children.push(categoryMap[category._id]);
          categoryMap[category._id].level = categoryMap[category.parent].level + 1;
        } else {
          processedCategories.push(categoryMap[category._id]);
        }
      });

      const flattenCategories = (categories) => {
        let flatList = [];
        categories.forEach(category => {
          flatList.push(category);
          if (category.children.length > 0) {
            flatList = flatList.concat(flattenCategories(category.children));
          }
        });
        return flatList;
      };

      return flattenCategories(processedCategories);
    },
    async getCategories() {
      const options = {
        headers: { "x-client-url": this.currentCompanyCatalog }
      };
      await this.axios.get(this.url('getClientCategories') + '?type=product', options)
        .then((res) => {
          const categories = res.data.objects;
          this.listCategory = this.processCategories(categories);
          // console.log("category", categories);
          // this.listCategory.unshift({ _id: 'all', name: 'All', level: 0 });
        });
    },
    getByPrice: _.debounce(function() {
      // console.log("getByPrice debounce call");
      this.getProducts();
    }, 500),
  },
  watch:{
    searchText:{
      handler: function (text) {
        if(text.length===0){
          this.searchStatus = false;
          this.getProducts();
        }
      },
    },
    from: {
      handler: function () {
        // console.log("Watche from")
        this.getByPrice();
      },
    },
    to: {
      handler: function () {
        // console.log("Watche to")
        this.getByPrice();
      },
    },
    
    "$route.query.categoryId": {
    immediate: true,
    handler(newCategoryId) {
      if (!newCategoryId || newCategoryId === this.selectedCategory) return;
      this.setCategory(newCategoryId);
    }
  }
  },
  created() {
    this.debouncedGetProducts = debounce(this.getProducts, 300);
  },
  mounted(){

    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
        this.currentPage = parseInt(savedPage);  // Восстанавливаем страницу из localStorage
    }

    const savedCategory = localStorage.getItem('currentCategory');
    if (savedCategory) {
        this.selectedCategory = savedCategory;  // Восстанавливаем категорию из localStorage
    }
    //this.getProducts();
    this.getProductsForPrice()
    // console.log("this.filteredList",this.filteredList)
    this.rangeSlider()
    // this.getRangeValues()
    this.getCategories()
    // this.getProducts()

    if (this.$route.query.categoryId !== undefined) {
      this.setCategory(this.$route.query.categoryId);
    } else {
      this.setCategory('all'); 
    }

}

}
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /* border: 1px solid red; */
  background: #F8F8F8;
  border-radius: 100px;
  padding: 5px;
  width: auto;
}
.price{
    font-size: 16px;
    font-weight: normal;
    margin: 35px 0;
}
.arrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease;
}
.arrow:hover {
  background: #000000;
  transition: all 0.3s ease;
}
.next-arrow {
  background: #000000;
  /* color: #fff */
}
.arrow-icon path {
  stroke: gray;
  fill: none;
  transition: stroke 0.3s ease;
}
.arrow:hover .arrow-icon path {
  stroke: white; 
}

.show {
  position: absolute !important;
  bottom: 10px;
  width: 93% !important;
  right:3.5%;

}
.mobile-btns{
  display:none;
}
@-webkit-keyframes fadeIn {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);}
}
.d-flexx{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.d-flexx div:hover{
  color:#616cf5!important;
}
.catalogSearchClearIcon{
  position: absolute;
  top: 12px;
  right: 28px;
}

.catalogSort{
  width: 100%;
  height: 30px;
  outline: none;
  border-style: none;
  padding: 0 16px;
  background: url("../../../assets/img/select_arrow.svg") no-repeat;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-position-x: calc(100% - 15px);
  background-position-y: 7px;
  color: #606877;
  font-size: 14px;
}
.catalogSort:focus-visible{
  border-style: none;
  outline: none;
}
.sortDropDown div{
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.sortDropDown div:hover{
  color:#616cf5!important;
}

.sortDropDown{
  position: absolute;
  width: 100%;
  background: #F4F4F4;
  border-radius: 10px;
  top: 35px;
  right: 0;
  padding: 10px;
  z-index: 100;
}
@media(max-width: 480px){
  .sortDropDown{
    position: absolute;
    width: 150%;
    background: #fff;
    color: black;
    border-radius: 12px;
    top: 55px;
    left: -50%;
    padding: 10px;
    box-shadow: 0px 0px 14px 0px #0000001A;
    z-index: 100;
    -webkit-animation: fadeIn 0.3s;
    animation-fill-mode:forwards;
  }
}

.selectWrapper{
  padding: 0 16px;
  color: #606877;
  font-size: 14px;
  height: 30px;
  border-radius: 20px;
  background: #F4F4F4;
  justify-content: center;
}
@media(max-width: 1200px){
  .selectWrapper{
    color: #606877;
    font-size: 14px;
    height: 40px;
    width: 50%;
    border-radius: 20px;
    background: #fff;
    justify-content: center;
    border: 1px solid #8C94A5;
    cursor: pointer;
  }
  .selectWrapper.active{
    border: 1px solid #616CF5;
  }
}
.box {
  /* border: 1px solid green; */
  height: 60vh;
}
.noProd {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #979797;
  font-weight: 500;
  font-size: 30px;
  /* border: 1px solid red; */
}
@media (max-width: 1200px) {
  .noProd {
    font-size: 20px;
  }
}

.catalogSearchInputIcon{
  position: absolute;
  top: 12px;
  left: 15px;
}
.catalogSearchInput{
  width: calc(100% - 13px);
  font-size: 18px;
  line-height: 24px;
  color: black;
  padding: 10px 120px 10px 40px;
  border-radius: 50px;
  border:1px solid #D3D3D3;
  margin-bottom: 5px;
}

.paginationItem{
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  /* border: 1px solid red */
}
.paginationItem:hover{
  background-color: #fff;
  color: #000000;
}

.activePage{
  background-color: #fff;
  color: #000000;
  font-weight: bold;
  width: 30px;
  height: 30px;
}
.showFilter.active{
  display:block;
  -webkit-animation: fadeIn 0.3s;
  animation-fill-mode:forwards;
}
.showCategory {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 10%;
  left: 0;
  display: block;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 14px 0px #0000001A;
  overflow-y: auto;
  height: 100vh;
  /* border: 1px solid red; */
  /* Изначально скрываем за левым краем */
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
}

/* При добавлении класса active – плавно выезжает */
.showCategory.active {
  transform: translateX(0);
  display:block;
  -webkit-animation: fadeIn 0.3s;
  animation-fill-mode:forwards;
}

.mobile-header {
  font-size: 22px;
  font-weight: 600;
  border: none;
  display: flex;
  gap: 20px;
  /* padding-left: 4px; */
  height: 15px;
  /* border: 1px solid red; */
  margin-top: 15px;
}
.close-nav {
  width: 20px;
  height: 20px;
}

.catalog-list{
  /* line-height: 1; */
  box-sizing: border-box;
  list-style-type: none;
  border:none;
  /* height: 35px; */
  display: flex;
  color: #303030;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
  transition: all 0.3s ease;
  /* border: 1px solid red; */
}
.catalog-list:hover{
  /* background: #EBEEFF; */
  color: #616CF5;
  cursor:pointer;
}
.catalog-list.active{
  color: #616CF5;
}
.flexing {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category-fade-enter-active, .category-fade-leave-active {
  transition: all 0.3s ease;
  max-height: 100px; /* Задаем примерную высоту */
  opacity: 1;
}

.category-fade-enter-from, .category-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.showFilter{
  position: absolute;
  width: 75%;
  z-index: 100;
  top: 87%;
  left:3%;
  display: none;
  transition: .4s;
  background:#fff;
  border-radius: 12px;
  padding: 20px 20px;
  /* padding:0 20px;
  padding-bottom: 5px; */
  box-shadow: 0px 0px 14px 0px #0000001A;
}

.filter-category{
  font-size: 18px;
  color:#222;
  font-weight: 600;
}
.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
.catalog{
  color:#222222;
  /* border: 1px solid red */
}
.category-key{
  color:#858585;
}
.category-value{
  color:#616cf5;
}
.save{
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
  position: relative;
}
.list-icon{
  position: absolute;
  left:10px;
  background-size: contain;
}
.new-product, .app-buttons-item{
  height: 40px;
  width: 50%;
}
.app-buttons-item{
  justify-content: center;
  color:#616cf5;
  position: relative;
}
.app-buttons-item img{
  position: absolute;
  left: 10px;
}
.catalog-left{
  padding-right:5px;
  /*position: sticky;*/
  top:40px;
  z-index: 1000;
  transition:0.3s;

}
.product-title{
  font-size: 35px !important;
  font-weight: bold !important;
  padding-bottom: 20px;
}
@media(max-width:1200px){
  .product-title{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
  }
}
.product-title img{
  margin-right: 10px;
}

.client-input{
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
}
.label-client{
  color: #8C94A5;
}

@media(max-width:481px){
  .catalog-left{
    display:none;
  }
  .mobile-btns{
    display: block;
  }

}


</style>