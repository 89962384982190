<template>
 <div>
    <div v-if="spinner">
      <CardSkeleton />
    </div>
   <div v-else class="container" >
    <div class="show-path" style="font-weight: 300; color: #808080;">
      <span @click="$router.push(`/${currentCompanyCatalog}/catalog-page?categoryId=all`)" style="color: #808080;">Catalog</span> / 
      <span 
        style="color: #808080;"
        v-if="getProduct && getProduct.category" 
        @click="$router.push(`/${currentCompanyCatalog}/catalog-page?categoryId=${getProduct.category?._id || 'all'}`)"
      >
        {{ getProduct.category.name }}
      </span>
      <span style="color: #808080;" v-else>No Category</span> / 
      <span style="color: #808080;">{{ getProduct ? getProduct.name : 'No Product' }}</span>
    </div>
      <div class="box">
        <div class="left-box">
          <h3 class="product-name">{{getProduct.name}}</h3>
          <div 
            class="product-img" 
            :class="{ active: getProduct.imgArray.length === 0 }" 
            :style="{ 
              backgroundImage: getProduct.error || getProduct.imgArray.length === 0 
                ? `url(${noProd})` 
                : `url(${server + '/' + getProduct.img})` 
            }"
            id="container"
          >
            <div class="search-icon" @click="openModal(0)" v-if="getProduct.imgArray.length > 0">
              <img src="../../../assets/clients/blackSearch.svg" alt="Search Icon">
            </div>
          </div>

          <div v-if="modalVisible" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
              <button class="close-modal2" @click="closeModal">×</button>
              <button class="close-modal" @click="closeModal">×</button>
              <img :src="server + '/' + getProduct.imgArray[modalIndex]" class="modal-image">
              
              <div v-if="getProduct.imgArray.length > 0" class="thumbnail-container">
                <button class="prev-btn2" @click="prevImage">&#10094;</button>
                <div v-for="(img, index) in getProduct.imgArray" :key="index" class="thumbnail-item" @click="setModalImage(index)">
                  <img :src="server + '/' + img" class="thumbnail-image">
                </div>
                <button class="next-btn2" @click="nextImage">&#10095;</button>
              </div>
            </div>
          </div>

          <div v-if="getProduct.imgArray.length > 0" class="slider-container">
            <button class="prev-btn" @click="prevSlide" :disabled="currentIndex === 0">&#10094;</button>

              <div class="gap">
                <div v-for="(img, index) in getProduct.imgArray" :key="index" class="slider-item">
                  <img :src="server + '/' + img" @click="changeMainImage(index)" :class="{ 'active-image': getProduct.img === img }">
                </div>
              </div>

            <button class="next-btn" @click="nextSlide" >&#10095;</button>
          </div>
        </div>
        <div class="right-box" :class="{active: getProduct.imgArray.length===0}">
          <div class="">
            <h3 class="product-name2">{{getProduct.name}}</h3>
            <h5 class="product-code">{{getProduct.code}}</h5>


            <div class="container-box">
              <div class="size-main" v-if="getProduct.colors.length > 0" ref="colorDropdown">
                <div class="size" :class="{ 'focused': open_colors, 'must': isValidationActive2 && this.getProduct.colors.length > 0 && !this.picked_color  }" 
                  @click="toggleDropdown">
                  <div class="text">
                    <div v-if="picked_color" :style="{ backgroundColor: picked_color?.value?.hex, width: '18px', height: '18px', borderRadius: '50%' }"></div>
                    <p style="margin-top: 13px;">{{ picked_color ? picked_color.name : 'Select color' }}</p>
                  </div>
                  <div class="arrow">
                    <div v-if="open_colors">
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
                      </svg>
                    </div>
                  </div>
                </div>
                
                <div class="size-box" v-if="open_colors">
                  <div 
                    v-for="(color, index) in getProduct.colors" 
                    :key="index" 
                    class="size-option" 
                    @click="picked_color = color"
                  >
                    <div class="size-name">
                      <input 
                        style="appearance: checkbox; width: 20px !important; height: 20px !important;" 
                        type="radio" 
                        :id="'color' + index" 
                        :value="color"
                        v-model="picked_color" 
                      />
                    </div>
                    <div class="color" :style="colorStyle(color.value.hex)"></div>
                    <span class="text">{{ color.name }}</span>
                  </div>
                </div>
              </div>
              <div ref="sizeMain" class="size-main" v-if="getProduct.hasMultipleTypes">
                <div class="size" :class="{ 'focused': open_sizes, 'must': isValidationActive && selectedSize._id === undefined && getProduct.hasMultipleTypes }" @click="toggleSizes">
                  <div class="text"><p style="margin-top: 13px;">{{ picked_size ? picked_size.size : 'Select size' }}</p></div>

                  <div class="arrow">
                    <div v-if="open_sizes">
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="size-box" v-if="open_sizes">
                  <div v-for="(size, index) in getProduct.sizes" @click="picked_size = size" :key="index" class="size-option">
                    <div class="size-name">
                      <input 
                        style="appearance:checkbox;" 
                        type="radio" 
                        :id="'size' + index" 
                        :value="size" 
                        v-model="picked_size" 
                      />
                      <span>{{ size.size }}</span>
                    </div>
                    <div class="size-price">
                      {{ size.price }} {{catalog_settings.currency}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>

            <div>
              <div v-if="getProduct.productCustomField1 && getProduct.productCustomField1 !== 'undefined'" class="d-flexx">
                <p style="font-weight: 600; font-size: 16px;">Материал: </p>
                <p>{{ getProduct.productCustomField1 }}</p>
              </div>
              <div v-if="getProduct.productCustomField2 && getProduct.productCustomField2 !== 'undefined'" class="d-flexx">
                <p style="font-weight: 600; font-size: 16px;">Страна: </p>
                <p>{{ getProduct.productCustomField2 }}</p>
              </div>
            </div>
            <br/>
            <div v-if="getProduct.description && getProduct.description !== ''">
              <h3 style="font-weight: 600; font-size: 16px; margin-bottom: 15px;">Description:</h3>
              
              <div class="text-container">
                <p 
                  ref="desc"
                  class="product-text" 

                >
                  {{ showMore ? getProduct.description : smallText }}
                  
                  <small
                    v-if="isLongText" 
                    @click="showMore = !showMore" 
                    class="show-more-btn"
                    :style="{ color: accentColor }"
                  >
                    {{ showMore ? '' : 'more' }}
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="flexCol">
            <div>
              <div class="d-flexx" v-if="getProduct.hasMultipleTypes">
                <h1 v-if="checkDates(getProduct.promoStart,getProduct.promoEnd)" class="product-price">{{getProduct.promoPrice}} {{catalog_settings.currency}}</h1>
                <h3 :class="{lineThrough:checkDates(getProduct.promoStart,getProduct.promoEnd)}" >{{selectedSize.price || getProduct.price}} {{catalog_settings.currency}}</h3>
              </div>

              <div class="d-flexx" v-else>
                <h1 v-if="checkDates(getProduct.promoStart,getProduct.promoEnd)" class="product-price">{{getProduct.promoPrice}} {{catalog_settings.currency}}</h1>
                <h3 :class="{lineThrough:checkDates(getProduct.promoStart,getProduct.promoEnd)}" >{{getProduct.price}} {{catalog_settings.currency}}</h3>
              </div>
            </div>

            <div class="add-buttons" v-if="settingsLoaded && settings && !settings.object.catalogMode">
              <div class="add-nums">
                <button class="increase" @click="decrease(getProduct._id)">-</button>
                <span v-if="getProductFromStore" class="count currentItemBasketQuantity">

                  {{getProductFromStore.quantity}}

                </span>
                <span v-else class="count currentItemBasketQuantity">0</span>
                <button class="increase" @click="addToCart" :disabled="!(getProduct.quantity > 0)">+</button>
              </div>

              <div class="add-cart">
                <button class="catalog-btn" 
                  :disabled="!(getProduct.quantity > 0)" 
                  :class="{ 'not-available': !(getProduct.quantity > 0) }" 
                  @click="addToCart()"
                >
                  <svg v-if="getProduct.quantity !== 0 && getProduct.quantity !== -1" class="cart-icon" width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8837 5.41797C15.6244 2.97474 13.5311 1.07036 10.9872 1.07036C9.68086 1.06493 8.42318 1.57453 7.49534 2.48651C6.69089 3.27719 6.19183 4.31387 6.07331 5.41797H6.07093C4.28759 5.41797 2.7893 6.71012 2.36837 8.95373L1.60666 14.7831C0.95828 19.0516 2.98179 20.9279 6.94198 20.9279H15.0481C18.7103 20.9279 20.8993 18.813 20.4498 15.0174L19.6634 8.9928C19.1593 6.72129 17.6996 5.41797 15.9547 5.41797H15.8837ZM14.4156 5.41797C14.1651 3.77097 12.7253 2.50807 10.9842 2.50806C10.0621 2.50423 9.17646 2.86308 8.5231 3.50527C7.9936 4.02571 7.65199 4.69751 7.5415 5.41797H14.4156ZM6.06953 6.85156H15.9533C16.9766 6.85156 17.8756 7.65424 18.2285 9.23519L18.9729 14.9627C19.4159 18.0099 17.9413 19.4861 15.0467 19.4861H6.94058C3.80618 19.4861 2.54124 18.3131 3.04781 14.9774L3.8054 9.17233C4.09488 7.63531 5.00366 6.85156 6.06953 6.85156ZM13.8665 9.52734C14.2687 9.52734 14.5948 9.84919 14.5948 10.2462C14.5948 10.6101 14.3208 10.9109 13.9653 10.9585L13.8665 10.9651H13.822C13.4198 10.9651 13.0938 10.6432 13.0938 10.2462C13.0938 9.88227 13.3677 9.58151 13.7232 9.53391L13.822 9.52734H13.8665ZM8.93362 10.2462C8.93362 9.84919 8.60756 9.52734 8.20534 9.52734H8.1609L8.06208 9.53391C7.7066 9.58151 7.43262 9.88227 7.43262 10.2462C7.43262 10.6432 7.75868 10.9651 8.1609 10.9651H8.20534L8.30416 10.9585C8.65964 10.9109 8.93362 10.6101 8.93362 10.2462Z" fill="currentColor"/>
                  </svg>
                  <a>{{ getProduct.quantity === 0 || getProduct.quantity === -1 ? 'Not available' : 'Add to cart' }}</a>
                </button>
              </div>
              <div class="add-cart-mobile">
                <button class="catalog-btn2" 
                  :disabled="!(getProduct.quantity > 0)" 
                  :class="{ 'not-available': !(getProduct.quantity > 0) }" 
                  @click="addToCart()"
                >
                  <svg v-if="getProduct.quantity !== 0 && getProduct.quantity !== -1" class="cart-icon2" width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8837 5.41797C15.6244 2.97474 13.5311 1.07036 10.9872 1.07036C9.68086 1.06493 8.42318 1.57453 7.49534 2.48651C6.69089 3.27719 6.19183 4.31387 6.07331 5.41797H6.07093C4.28759 5.41797 2.7893 6.71012 2.36837 8.95373L1.60666 14.7831C0.95828 19.0516 2.98179 20.9279 6.94198 20.9279H15.0481C18.7103 20.9279 20.8993 18.813 20.4498 15.0174L19.6634 8.9928C19.1593 6.72129 17.6996 5.41797 15.9547 5.41797H15.8837ZM14.4156 5.41797C14.1651 3.77097 12.7253 2.50807 10.9842 2.50806C10.0621 2.50423 9.17646 2.86308 8.5231 3.50527C7.9936 4.02571 7.65199 4.69751 7.5415 5.41797H14.4156ZM6.06953 6.85156H15.9533C16.9766 6.85156 17.8756 7.65424 18.2285 9.23519L18.9729 14.9627C19.4159 18.0099 17.9413 19.4861 15.0467 19.4861H6.94058C3.80618 19.4861 2.54124 18.3131 3.04781 14.9774L3.8054 9.17233C4.09488 7.63531 5.00366 6.85156 6.06953 6.85156ZM13.8665 9.52734C14.2687 9.52734 14.5948 9.84919 14.5948 10.2462C14.5948 10.6101 14.3208 10.9109 13.9653 10.9585L13.8665 10.9651H13.822C13.4198 10.9651 13.0938 10.6432 13.0938 10.2462C13.0938 9.88227 13.3677 9.58151 13.7232 9.53391L13.822 9.52734H13.8665ZM8.93362 10.2462C8.93362 9.84919 8.60756 9.52734 8.20534 9.52734H8.1609L8.06208 9.53391C7.7066 9.58151 7.43262 9.88227 7.43262 10.2462C7.43262 10.6432 7.75868 10.9651 8.1609 10.9651H8.20534L8.30416 10.9585C8.65964 10.9109 8.93362 10.6101 8.93362 10.2462Z" fill="currentColor"/>
                  </svg>
                  <a>{{ getProduct.quantity === 0 || getProduct.quantity === -1 ? 'Not available' : 'Add to cart' }}</a>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
   </div>
 </div>

</template>

<script>
import noProd from '../../../assets/icons/noProd.svg';
import $ from 'jquery';
import Swal from "sweetalert2";
import CardSkeleton from "../../../components/skeleton/CardSkeleton.vue";
export default {
  name: "CatalogDetail",
  components:{
    CardSkeleton,
  },
  data(){
    return{
      isBlueBorder: false,
      picked_size: null,
      picked_color: null,
      checked: '',
      showMore: false,
      maxLength: 100,
      isLongText: false,
      smallText: '',
      modalVisible: false, 
      modalIndex: 0, // Индекс выбранного изображения
      selectedSize:{
        price:"",
      },
      today:new Date(),
      getProduct: {
        imgArray:[],
      },

      open_sizes: false,
      open_colors: false,
      settings: null,
      settingsLoaded: false,
      spinner:true,
      noProd: noProd,
      currentIndex: 0,  // Начальный индекс
      visibleSlides: 4,
      isValidationActive: false,
      isValidationActive2: false,
    }
  },
  watch: {
    "getProduct.imgArray": function() {
      this.$nextTick(() => {
        $('.multiple-items').slick('refresh');
      });
    },
    picked_size(newSize) {
      if (newSize) {
        this.selectedSize = newSize;
        console.log(this.selectedSize);
        this.matchSizeQuantity();
      } else {
        // Очистить selectedSize, если ничего не выбрано
        this.selectedSize = {
          price: "",
        };
      }
    },
    'getProduct.description': function () {
      this.$nextTick(() => {
        this.checkTextHeight();
      });
    },
    smallText(newValue) {
      console.log("🔄 smallText изменился:", newValue);
    },
    modalVisible(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  computed:{
    accentColor() {
      return this.settings?.object?.accentColor?.value?.hex || '#000000';
    },
    truncatedText() {
      if (this.getProduct.description.length > this.maxLength) {
        return this.getProduct.description.slice(0, this.maxLength) + "...";
      }
      return this.getProduct.description;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    currentCompanyCatalog() {
        // Return the truncated description with ellipsis
      return this.$route.params.bekon;
    },
      // Return the full description if it's within the limit
    userDiscountStatus(){
      return this.$store.getters['Client/getUserDiscountStatus'];
    },
    getProductFromStore(){
      this.matchSizeQuantity();
      return this.$store.getters['Orders/getProduct'](this.getProduct._id)[0];
    },
    shoppingCart(){
      return this.$store.state.Orders.shoppingCart;
    },
    server(){
      return this.$server;
    },
    company_url_basket(){
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
  },
  methods: {
    setModalImage(index) {
      this.modalIndex = index;
    },
    changeMainImage(index) {
      this.getProduct.img = this.getProduct.imgArray[index]; // Меняем главное фото
    },
    nextSlide() {
      if (this.currentIndex < this.getProduct.imgArray.length - 1) {
        this.currentIndex++;
        this.changeMainImage(this.currentIndex);  // Обновляем главное изображение
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.changeMainImage(this.currentIndex);  // Обновляем главное изображение
      }
    },
    openModal(index) {
      this.modalIndex = index;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    prevImage() {
      if (this.modalIndex > 0) {
        this.modalIndex--;
      } else {
        this.modalIndex = this.getProduct.imgArray.length - 1;
      }
    },
    nextImage() {
      console.log("click")

      if (this.modalIndex < this.getProduct.imgArray.length - 1) {
        this.modalIndex++;
      } else {
        this.modalIndex = 0;
      }
    },
    colorStyle(hex) {
      const isLight = this.isLightColor(hex);
      return {
        background: hex,
        border: isLight ? '1px solid #ccc' : 'none'
      };
    },
    toggleDropdown() {
      this.isValidationActive2 = false;
      this.open_colors = !this.open_colors;
      if (this.open_colors) {
        document.addEventListener("click", this.closeOnClickOutside);
      } else {
        document.removeEventListener("click", this.closeOnClickOutside);
      }
    },
    closeOnClickOutside(event) {
      if (this.$refs.colorDropdown && !this.$refs.colorDropdown.contains(event.target)) {
        this.open_colors = false;
        document.removeEventListener("click", this.closeOnClickOutside);
      }
    },
    toggleSizes() {
      this.isValidationActive = false;
      this.open_sizes = !this.open_sizes;
      if (this.open_sizes) {
        document.addEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      if (this.$refs.sizeMain && !this.$refs.sizeMain.contains(event.target)) {
        this.open_sizes = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    isLightColor(hex) {
      const rgb = this.hexToRgb(hex);
      if (!rgb) return false;
      
      // Вычисляем яркость по формуле: (R * 299 + G * 587 + B * 114) / 1000
      const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
      
      // Проверяем, насколько цвет близок к белому
      const whiteness = (Math.min(rgb.r, rgb.g, rgb.b) / 255) * 100;
      
      // Если яркость больше 225 или белизна больше 90%, считаем цвет светлым
      return brightness > 225 || whiteness > 90;
    },

    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    getSettings() {
      const options = {
        headers: {"X-Client-Url": this.$route.params.bekon}
      }
      // console.log("catalog_Settings data", this.catalog_settings)
      let that = this;
      this.axios.get(this.url('getCatalogSettings'), options).then(function(response) {
        // console.log("Settings res get", response.data);
        that.settings = response.data;
        that.settingsLoaded = true; // Устанавливаем флаг, что настройки загружены
        console.log("that.settings", that.settings.object.accentColor.value.hex)
      }).catch(function(error) {
        console.error("Ошибка при загрузке настроек:", error);
        that.settingsLoaded = true; // Устанавливаем флаг даже в случае ошибки
      });
    },
    toggleBlueBorder() {
      console.log("before toggleBlueBorder", this.isBlueBorder)
      this.isBlueBorder = !this.isBlueBorder;
      console.log("after toggleBlueBorder", this.isBlueBorder)
    },
    selectSize(size){
      // let index = this.getProduct.sizes.findIndex(x => x.size === size.target.value);
      let index = this.getProduct.sizes.findIndex(x => x.size === size);
      if(index !== -1){
        console.log(this.getProduct.sizes[index])
        this.selectedSize = this.getProduct.sizes[index];
        this.matchSizeQuantity();
      }
    },
    matchSizeQuantity(){
      let allProds = this.$store.getters['Orders/getProduct'](this.getProduct._id);
      let size = this.selectedSize;
      let index = allProds.findIndex(x => x.size._id==size._id);
      if(index !== -1){
        $('.currentItemBasketQuantity').text(allProds[index].quantity);
      }else{
        $('.currentItemBasketQuantity').text("0");
      }
    },
    increase(id,stock_quant,basket_quant){
      if(stock_quant>basket_quant || stock_quant === -1){
        this.$store.dispatch('Orders/increaseQuantity', id);
        this.$emit('checkPromocode_child',this.basket_promocode);
      }else{
        this.$warningAlert('Not enough stock');
      }
    },
    decrease(id){
      this.$store.dispatch('Orders/decreaseQuantity', id);
      this.$emit('checkPromocode_child',this.basket_promocode);
    },
    checkDates(start,end){
      let itsPromo = false;
      let startDate = new Date(start);
      let endDate = new Date(end);
      if(startDate<=this.today && endDate>=this.today){
        itsPromo = true;
      }
      return itsPromo;
    },
    addToCart(){
      if (this.selectedSize._id === undefined && this.getProduct.hasMultipleTypes && this.getProduct.colors.length > 0 && !this.picked_color) {
        this.isValidationActive = true;
        this.isValidationActive2 = true;
        const backdrop = document.createElement('div');
        backdrop.style.position = 'fixed';
        backdrop.style.top = '0';
        backdrop.style.left = '0';
        backdrop.style.width = '100%';
        backdrop.style.height = '100%';
        backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        backdrop.style.zIndex = '1001';
        document.body.appendChild(backdrop);

        Swal.fire({
          title: '<div style="color: white; font-size: 18px; margin-left: 45px;">Warning</div>',
          html: `
            <div style="margin-top: -30px;">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 29.7057C7.62214 29.7057 1.29297 23.3766 1.29297 15.4974C1.29297 7.61823 7.62214 1.28906 15.5013 1.28906C23.3805 1.28906 29.7096 7.61823 29.7096 15.4974C29.7096 23.3766 23.3805 29.7057 15.5013 29.7057Z" fill="white"/>
                
                <path d="M15.5013 3.8724C9.04297 3.8724 3.8763 9.03906 3.8763 15.4974C3.8763 21.9557 9.04297 27.1224 15.5013 27.1224C21.9596 27.1224 27.1263 21.9557 27.1263 15.4974C27.1263 9.03906 21.9596 3.8724 15.5013 3.8724Z" fill="#fff"/>
                
                <path d="M15.5013 16.7891C14.7263 16.7891 14.2096 16.2724 14.2096 15.4974V10.3307C14.2096 9.55573 14.7263 9.03906 15.5013 9.03906C16.2763 9.03906 16.793 9.55573 16.793 10.3307V15.4974C16.793 16.2724 16.2763 16.7891 15.5013 16.7891Z" fill="#FDB62E"/>
                
                <path d="M15.5013 21.9557C15.1138 21.9557 14.8555 21.8266 14.5971 21.5682C14.3388 21.3099 14.2096 21.0516 14.2096 20.6641C14.2096 20.5349 14.2096 20.2766 14.3388 20.1474C14.468 20.0182 14.468 19.8891 14.5971 19.7599C14.9846 19.3724 15.5013 19.2432 16.018 19.5016C16.1471 19.5016 16.1471 19.5016 16.2763 19.6307C16.2763 19.6307 16.4055 19.7599 16.5346 19.7599C16.6638 19.8891 16.793 20.0182 16.793 20.1474C16.793 20.2766 16.793 20.5349 16.793 20.6641C16.793 20.7932 16.793 21.0516 16.6638 21.1807C16.5346 21.3099 16.5346 21.4391 16.4055 21.5682C16.1471 21.8266 15.8888 21.9557 15.5013 21.9557Z" fill="#FDB62E"/>
              </svg>
            </div>
            <div style="color: white; font-size: 14px; margin-left: 45px; margin-top: -10px;">Please select size and color</div>
            <style>
              .swal2-popup {
                border-radius: 13px !important;
                z-index: 9999 !important;
                box-shadow: none !important;
              }
              .swal2-close {
                color: white !important;
                font-size: 25px !important;
              }
              .swal2-icon {
                color: white !important;
                font-size: 12px !important;
              }
            </style>
          `,
          position: 'top-end',
          background: '#FDB62E',
          width: '310px',
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          toast: true,
          customClass: {
            popup: 'success-popup warning-popup',
            content: 'success-content',
            title: 'success-title',
            header: 'success-header',
            image: 'success-img'
          },
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          },
          willClose: () => {
            backdrop.classList.add('animate__animated', 'animate__fadeOut');
            backdrop.addEventListener('animationend', () => {
              document.body.removeChild(backdrop);
            });
          }
        });
        return
      }
      if (this.selectedSize._id === undefined && this.getProduct.hasMultipleTypes) {
        this.isValidationActive = true;
        const backdrop = document.createElement('div');
        backdrop.style.position = 'fixed';
        backdrop.style.top = '0';
        backdrop.style.left = '0';
        backdrop.style.width = '100%';
        backdrop.style.height = '100%';
        backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        backdrop.style.zIndex = '1001';
        document.body.appendChild(backdrop);

        Swal.fire({
          title: '<div style="color: white; font-size: 18px; margin-left: 45px;">Warning</div>',
          html: `
            <div style="margin-top: -30px;">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 29.7057C7.62214 29.7057 1.29297 23.3766 1.29297 15.4974C1.29297 7.61823 7.62214 1.28906 15.5013 1.28906C23.3805 1.28906 29.7096 7.61823 29.7096 15.4974C29.7096 23.3766 23.3805 29.7057 15.5013 29.7057Z" fill="white"/>
                
                <path d="M15.5013 3.8724C9.04297 3.8724 3.8763 9.03906 3.8763 15.4974C3.8763 21.9557 9.04297 27.1224 15.5013 27.1224C21.9596 27.1224 27.1263 21.9557 27.1263 15.4974C27.1263 9.03906 21.9596 3.8724 15.5013 3.8724Z" fill="#fff"/>
                
                <path d="M15.5013 16.7891C14.7263 16.7891 14.2096 16.2724 14.2096 15.4974V10.3307C14.2096 9.55573 14.7263 9.03906 15.5013 9.03906C16.2763 9.03906 16.793 9.55573 16.793 10.3307V15.4974C16.793 16.2724 16.2763 16.7891 15.5013 16.7891Z" fill="#FDB62E"/>
                
                <path d="M15.5013 21.9557C15.1138 21.9557 14.8555 21.8266 14.5971 21.5682C14.3388 21.3099 14.2096 21.0516 14.2096 20.6641C14.2096 20.5349 14.2096 20.2766 14.3388 20.1474C14.468 20.0182 14.468 19.8891 14.5971 19.7599C14.9846 19.3724 15.5013 19.2432 16.018 19.5016C16.1471 19.5016 16.1471 19.5016 16.2763 19.6307C16.2763 19.6307 16.4055 19.7599 16.5346 19.7599C16.6638 19.8891 16.793 20.0182 16.793 20.1474C16.793 20.2766 16.793 20.5349 16.793 20.6641C16.793 20.7932 16.793 21.0516 16.6638 21.1807C16.5346 21.3099 16.5346 21.4391 16.4055 21.5682C16.1471 21.8266 15.8888 21.9557 15.5013 21.9557Z" fill="#FDB62E"/>
              </svg>
            </div>
            <div style="color: white; font-size: 14px; margin-left: 45px; margin-top: -10px;">Please select size</div>
            <style>
              .swal2-popup {
                border-radius: 13px !important;
                z-index: 9999 !important;
                box-shadow: none !important;
              }
              .swal2-close {
                color: white !important;
                font-size: 25px !important;
              }
              .swal2-icon {
                color: white !important;
                font-size: 12px !important;
              }
            </style>
          `,
          position: 'top-end',
          background: '#FDB62E',
          width: '310px',
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          toast: true,
          customClass: {
            popup: 'success-popup warning-popup',
            content: 'success-content',
            title: 'success-title',
            header: 'success-header',
            image: 'success-img'
          },
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          },
          willClose: () => {
            backdrop.classList.add('animate__animated', 'animate__fadeOut');
            backdrop.addEventListener('animationend', () => {
              document.body.removeChild(backdrop);
            });
          }
        });
        return
      }

      if(this.getProduct.colors.length > 0 && !this.picked_color){
        this.isValidationActive2 = true;
        const backdrop = document.createElement('div');
        backdrop.style.position = 'fixed';
        backdrop.style.top = '0';
        backdrop.style.left = '0';
        backdrop.style.width = '100%';
        backdrop.style.height = '100%';
        backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        backdrop.style.zIndex = '1001';
        document.body.appendChild(backdrop);

        Swal.fire({
          title: '<div style="color: white; font-size: 18px; margin-left: 45px;">Warning</div>',
          html: `
            <div style="margin-top: -30px;">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 29.7057C7.62214 29.7057 1.29297 23.3766 1.29297 15.4974C1.29297 7.61823 7.62214 1.28906 15.5013 1.28906C23.3805 1.28906 29.7096 7.61823 29.7096 15.4974C29.7096 23.3766 23.3805 29.7057 15.5013 29.7057Z" fill="white"/>
                
                <path d="M15.5013 3.8724C9.04297 3.8724 3.8763 9.03906 3.8763 15.4974C3.8763 21.9557 9.04297 27.1224 15.5013 27.1224C21.9596 27.1224 27.1263 21.9557 27.1263 15.4974C27.1263 9.03906 21.9596 3.8724 15.5013 3.8724Z" fill="#fff"/>
                
                <path d="M15.5013 16.7891C14.7263 16.7891 14.2096 16.2724 14.2096 15.4974V10.3307C14.2096 9.55573 14.7263 9.03906 15.5013 9.03906C16.2763 9.03906 16.793 9.55573 16.793 10.3307V15.4974C16.793 16.2724 16.2763 16.7891 15.5013 16.7891Z" fill="#FDB62E"/>
                
                <path d="M15.5013 21.9557C15.1138 21.9557 14.8555 21.8266 14.5971 21.5682C14.3388 21.3099 14.2096 21.0516 14.2096 20.6641C14.2096 20.5349 14.2096 20.2766 14.3388 20.1474C14.468 20.0182 14.468 19.8891 14.5971 19.7599C14.9846 19.3724 15.5013 19.2432 16.018 19.5016C16.1471 19.5016 16.1471 19.5016 16.2763 19.6307C16.2763 19.6307 16.4055 19.7599 16.5346 19.7599C16.6638 19.8891 16.793 20.0182 16.793 20.1474C16.793 20.2766 16.793 20.5349 16.793 20.6641C16.793 20.7932 16.793 21.0516 16.6638 21.1807C16.5346 21.3099 16.5346 21.4391 16.4055 21.5682C16.1471 21.8266 15.8888 21.9557 15.5013 21.9557Z" fill="#FDB62E"/>
              </svg>
            </div>
            <div style="color: white; font-size: 14px; margin-left: 45px; margin-top: -10px;">Please select color</div>
            <style>
              .swal2-popup {
                border-radius: 13px !important;
                z-index: 9999 !important;
                box-shadow: none !important;
              }
              .swal2-close {
                color: white !important;
                font-size: 25px !important;
              }
              .swal2-icon {
                color: white !important;
                font-size: 12px !important;
              }
            </style>
          `,
          position: 'top-end',
          background: '#FDB62E',
          width: '310px',
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          toast: true,
          customClass: {
            popup: 'success-popup warning-popup',
            content: 'success-content',
            title: 'success-title',
            header: 'success-header',
            image: 'success-img'
          },
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          },
          willClose: () => {
            backdrop.classList.add('animate__animated', 'animate__fadeOut');
            backdrop.addEventListener('animationend', () => {
              document.body.removeChild(backdrop);
            });
          }
        });
        return;
      }
      //check if its the same company
      console.log(this.company_url_basket,this.$route.params.bekon,"STORAGE CHECK");
      if(this.company_url_basket !==this.$route.params.bekon){
        //clear local storage
        console.log('clear');
        this.$store.dispatch("Orders/clearAll");
      }
      let that = this;
      let cart_object = {
        size:this.selectedSize,
        color: this.picked_color,
        client_status_discount:this.userDiscountStatus.discount_percentage || 0,
        product:{},
        isDiscounted:false,
        quantity:1,
        promocode:'',
        discount_percent:0,
        discount_percent_sum:0,
        discount_sum:0,
        current_price:0,
      }
      cart_object.product = this.getProduct;
      let current_price = 0;
      if(cart_object.size._id !== undefined && cart_object.product.hasMultipleTypes){
        //promo price check
        let sizeIndex = cart_object.product.sizes.findIndex(x => x._id === cart_object.size._id)
        let sizeObject = cart_object.product.sizes[sizeIndex];
        if(sizeIndex !== -1) {
          current_price = parseFloat(sizeObject.price) || 0;
          let old_price = parseFloat(sizeObject.price) || 0;

          if(this.checkDates(cart_object.product.promoStart,cart_object.product.promoEnd)){
            current_price = this.getProduct.promoPrice || 0;
            let discount_sum = old_price - current_price;
            cart_object.discount_sum = discount_sum>0? discount_sum:0;
            cart_object.isDiscounted = true;
          }else if(cart_object.client_status_discount>0){
            let disc = (current_price*(cart_object.client_status_discount/100)).toFixed(2);
            current_price = (current_price-disc).toFixed(2);
            cart_object.discount_percent_sum =disc;
          }
        }

        //
      }else{
        //promo price check
        current_price = this.getProduct.price || 0;
        let old_price = this.getProduct.price || 0;
        if(this.checkDates(cart_object.product.promoStart,cart_object.product.promoEnd)){
          current_price = this.getProduct.promoPrice || 0;
          let discount_sum = old_price - current_price;
          cart_object.discount_sum = discount_sum>0? discount_sum:0;
          cart_object.isDiscounted = true;
        }else if(cart_object.client_status_discount>0){
          let disc = (current_price*(cart_object.client_status_discount/100)).toFixed(2);
          current_price = (current_price-disc).toFixed(2);
          cart_object.discount_percent_sum =disc;
        }
        //
      }

      cart_object.current_price = current_price;
      this.$store.dispatch('Orders/addToCart', cart_object);
      this.$store.dispatch('Orders/setCompany_url_basket', that.$route.params.bekon);
      //update/set storage version
      let version = new Date();
      this.$store.dispatch("Orders/setVersion",version);
    },

    async getOneProduct() {
      this.spinner = true;
      const options = {
        headers: { "x-client-url": this.currentCompanyCatalog }
      };
      
      await this.axios.get(this.url('getProductWeb', this.$route.params.id), options)
        .then((response) => {
          console.log("response", response);
          this.getProduct = response.data.object;
          console.log("this.getProduct", this.getProduct.description.length);

          if (this.getProduct.img && !this.getProduct.imgArray.includes(this.getProduct.img)) {
            this.getProduct.imgArray.unshift(this.getProduct.img);
          }
          if (this.getProduct.description) {
            this.smallText = this.getProduct.description.slice(0, 260) + "...";
          }

          this.spinner = false;
        }) 
        .catch((error) => {
          this.spinner = false;
          console.log(error)
        });
    },
    checkTextHeight() {
    this.$nextTick(() => {
      const desc = this.$refs.desc;
      if (desc) {
        const lineHeight = parseInt(window.getComputedStyle(desc).lineHeight);
        const maxHeight = lineHeight * 2; // 3 строки
        this.isLongText = desc.scrollHeight > maxHeight; // Проверяем высоту
      }
    });
  }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mounted(){
    this.getSettings();
    this.getOneProduct();
    this.$nextTick(() => {
      this.checkTextHeight();
    }); 
  }
}
</script>

<style scoped>
/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 80%;
  max-height: 70%;
  background-color: transparent;
  border-radius: 10px;
  border: none;
}

.modal-image {
  width: 575px;
  height: 575px;
  display: block;
  margin-bottom: 20px;
  border-radius: 20px;
  margin-left: 25%;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .modal-image {
    width: 100%;
    height: 320px;
    display: block;
    margin-left: 0%;
  }
  .modal-content {
    margin-top: 50px;
  }
} 

.prev-btn2, .next-btn2 {
  font-size: 20px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.prev-btn2:hover, .next-btn2:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 1200px) {
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    background: white;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    color: black;
    cursor: pointer;
  }
  .close-modal2 {
    display: none;
  }
}
@media (max-width: 1200px) {
  .close-modal {
    display: none;
  }
}
@media (max-width: 1200px) {
  .close-modal2 {
    margin-left: 50%;
    margin-bottom: 15px;
    top: 17%;
    font-size: 25px;
    background: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    color: black;
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .close-modal2 {
    margin-left: 45%;
    margin-bottom: 15px;
    top: 17%;
    font-size: 25px;
    background: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    color: black;
    cursor: pointer;
  }
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.thumbnail-item {
  margin: 0 5px;
  cursor: pointer;
}

.thumbnail-image {
  width: 60px;
  height: auto;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out;
}

.thumbnail-item:hover .thumbnail-image {
  border-color: #aeaeae;
}




.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 10px;
  height: auto;
  margin-top: 30px;
  padding: 0px 20px;
  /* overflow: hidden; */
  /* border: 1px solid red; */
  overflow-x: scroll;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none;
}
.slider-container::-webkit-scrollbar {
  display: none; 
}
@media (max-width: 992px) {
  .slider-container {
    margin-top: 20px;
    height: auto;
    /* overflow: hidden; */
  }
}
.slider-item img {
  width: 124px;
  height: 124px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease-in-out;
  border-radius: 10px;
  object-fit: cover;
  /* border: 1px solid red; */
}
.gap {
  display: flex;
  gap: 10px;
}
@media (max-width: 1200px) {
  .slider-item img {
    width: 68px;
    height: 68px;
  }
} 
.active-image {
  border: 2px solid #AEAEAE !important;
}


.slider-item img:hover {
  border: 2px solid #AEAEAE;
}

.multiple-items {
  width: 100%;
  height: 125px;
  /* border: 1px solid red; */
  display: flex;
  gap: 10px
}


.prev-btn,
.next-btn {
  background-color: white;
  color: #B6B6B6;
  border: none;
  cursor: pointer;
  font-size: 22px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10 !important;
  /* border: 1px solid red */
}

.prev-btn {
  left: 1px;
}

.next-btn {
  right: 1px;
}

.count {
  font-size: 20px;
}
.flexCol {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.increase {
  border: 1px none;
  margin-top: 28px;
  color: black;
  font-size: 25px;
}
.catalog-btn{
    background: #616CF5;
    color:#fff;
    border:none;
    width: 381px;
    height: 50px;
    font-size: 16px;
    border-radius:25px;
    border: 1px solid #114C9A;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
/* @media (max-width: 992px) {
  .catalog-btn{
    width: 220px;
  }
} */
.catalog-btn:hover{
    background: #fff;
    transition:0.3s;
    border: 1px solid #3B4DB3;
    color: #616CF5;
}
.catalog-btn .cart-icon {
  transition: fill 0.3s ease;
  fill: #fff;
  display: flex;
}
.catalog-btn:hover .cart-icon {
  fill: #616CF5;
}
.catalog-btn2{
    background: white;
    color: #616CF5;
    border:none;
    width: 220px;
    height: 50px;
    font-size: 16px;
    border-radius:25px;
    border: 1px solid #616CF5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.catalog-btn2:hover{
    background: #616CF5;
    transition:0.3s;
    border: 1px solid #3B4DB3;
    color: #fff;
}
.catalog-btn2 .cart-icon2 {
  transition: fill 0.3s ease;
  fill: #616CF5;
  display: flex;
}
.catalog-btn2:hover .cart-icon2 {
  fill: #616CF5;
}
.not-available {
  cursor: not-allowed;
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
  /* width: 381px; */
  /* border: 1px solid red; */
}
@media(max-width: 980px) {
  .not-available {
  cursor: not-allowed;
  color: #898989;
  background: #F4F4F4;
  width: 220px;
}
}
.not-available:hover{
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
}
.box2 {
  width: 100%;
  /* border: 1px solid red; */
  padding-left: 10px;
}
.d-flexx {
  display: flex;
  gap: 5px;
  margin: 0;
  align-items: center;
  line-height: 0.7;
}
.left-box {
  width: 590px;
  height: auto;
}
@media (max-width: 1200px) {
  .left-box {
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
  }
  .right-box {
    width: 100%;
    height: auto !important;
  }
}
.right-box {
  margin-top: 15px;
  /* border: 1px solid red; */
  height: 560px;
  max-height: 717px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box{
  width: 100%;
  display: flex;
  gap: 40px;
  /* border: 1px solid red; */
}
@media (max-width: 1200px) {
  .box{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid red; */
  }
}
.size-box .color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  box-sizing: border-box;
}

.size-main {
  width: 215px;
  margin-right: 10px;
  position: relative;
  /* border: 1px solid red; */
}

.container-box {
  display: flex;
  flex-direction:row;
  gap: 20px;
  margin-top: 20px;
}

.size-box {
  box-shadow: 0px 15px 20px 1px #0000001A;
  max-width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #FFF;
  width: 100%;
  border: 1px solid #D8D8D8;
}

.size-option .size-price {
  margin-right: 5px;
  border: none;
  text-align: center; /* Центрируем текст в инпуте */
  padding: 2px;
  margin-left: auto; /* Сдвигаем input в правый край */
}

.size-option .size-name {
  padding-left: 10px;
  font-size: 14.5px;
  margin: 0;
  flex-grow: 0; /* Оставляем текст в его обычном размере */
}

.size-option .size-name span {
  padding-left: 10px;
}

.size-option {
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  max-width: 100%;
  background-color: #FFF;
  cursor: pointer;
}
.size-option:hover {
  background-color: #F8F9FB;
}

.size {
  border-radius: 100px;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #C9C9C9;
}
.size:hover {
  border: 1px solid #616cf5;
}
@media (max-width: 1200px) {
  .size {
    border-radius: 100px;
    height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #C9C9C9;
  }
  .size-main {
    width: 100%;
    margin-right: 10px;
    position: relative;
    /* border: 1px solid red; */
  }
  .container-box {
    display: flex;
    flex-direction:column;
    gap: 0px;
    margin-top: 20px;
  }
}
.focused {
  border: 1px solid #616cf5;
}
.must {
  border: 1px solid red;
}

.size .text {
  padding-left: 10px;
  font-size: 14.5px;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* border: 1px solid red; */
}

.size .arrow {
  margin-right: 5px;
  width: 25px; /* Фиксированная ширина инпута */
  border: none;
  text-align: center; /* Центрируем текст в инпуте */
  padding: 2px;
  margin-left: auto; /* Сдвигаем input в правый край */
}
.line {
  /* border: 1px solid red; */
  width: 100% !important;
}

.add-buttons {
  border-radius: 5px;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  /* border: 1px solid red; */
}

.add-nums {
  flex-grow: 0;
  border: 1px solid #C9C9C9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
}
.add-cart-mobile {
  display: none;
}
@media(max-width: 1200px) {
  .add-cart-mobile {
    border: none;
    text-align: center;
    display: flex;
  }
}
@media(max-width: 1200px) {
  .add-buttons .add-cart {
    display: none;
  }
}
@media(min-width: 1200px) {
  .add-buttons .add-cart {
    border: none;
    text-align: center;
    display: flex;
  }
}
.blue-border {
  border: 3px solid blue;
}

.gray-border {
  border: 3px solid gray;
}

.product-img {
  position: relative;
  height: 590px;
  width: 590px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  display: flex;
  z-index: 10 !important;
  
  /* Добавляем плавную анимацию */
  /* transition: background-image 0.3s ease-in-out, opacity 0.5s ease-in-out; */
}

.search-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 45px; 
  height: 45px; 
  cursor: pointer;
  z-index: 9999 !important;
  object-fit: contain;
  background: #fff !important;
  border-radius: 50%;
  padding: 9px;
  display: flex; 
  align-items: center; 
  justify-content: center;
}
.search-icon img {
  width: 25px;
  height: 25px;
}


@media (max-width: 992px) {
  .product-img {
    height: 345px !important;
    width: 100%;
    margin-bottom: 40px;
    position: relative; 
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    z-index: 10 !important;
  }
}

.selectedSizeClass{
  color:#616cf5!important;
  background: #616cf54f!important;
  border:2px solid #616cf5!important;
}
.sizeItemClass:hover{
  background: #f061f56e!important;
}
.sizeItemClass{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: white;
  border:2px solid #606877;
  color:#606877;
  border-radius: 5px;
  margin-right:5px;
  margin-bottom:5px;
}

.lineThrough{
  text-decoration: line-through;
  font-size: 20px!important;
  font-weight: 600 !important;
  color: #000000;
}
@media (min-width: 1200px) {
  .product-name {
    display: none;
  }
}
.product-name{
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}
.product-name2{
  font-weight: 600;
  font-size: 24px;
}
@media (max-width: 1200px) {
  .product-name2 {
    display: none;
  }
}
.product-code{
  color: #B0B0B0;
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0;
}
.product-price{
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #BF2C2C;
}
.text-container {
  display: flex;
  position: relative;
  /* border: 1px solid red; */
}

.product-text {
  color: #000000;
  font-weight: 400;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.show-more-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
}
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
    padding: 17px;
    padding-top: 0;
    /* border: 1px solid red */
  }
}
@media(max-width:992px){
  .product-img{
    height: 400px;
  }
  .multiple-items{
    margin-bottom: 0;
    /* border: 1px solid red; */
    height: 70px;
    display: flex;
  }
}
@media(max-width:481px) {
  .product-img {
    width: 100%;
    height: 100%;
    margin-bottom: 0
  }

  .product-img.active {
    height: 100%;
  }


}

</style>