<template>
  <div class="container">
    <nav class="navigation d-flex align-items-center">
      <!-- Логотип -->
      <router-link :to="`/${currentCompanyCatalog}`" class="brand-navbar">
        <img class="web-catalog-logo" v-if="catalog_settings.logo" :src="server + '/' + catalog_settings.logo" />
        <span v-else>{{ catalog_settings.name || 'Name' }}</span>
      </router-link>

      <!-- Поиск -->
      <div class="search-input">
        <input 
          type="text" 
          v-model="searchQuery" 
          class="input" 
          placeholder="Search" 
          @input="filterProducts"
          @focus="isFocused = true"
          @blur="isFocused = false"
          ref="searchInput"
        />
        <div class="nav-icon2">
          <img src="../assets/clients/search.svg" alt="Search" />
        </div>

        <!-- Результаты поиска -->
        <div class="search-results-wrapper" v-if="searchQuery" ref="searchResultsWrapper">
          <div class="search-results">
            <ul v-if="filteredProducts.length">
              <li v-for="product in limitedFilteredProducts" :key="product.id">
                <div @click="goToCatalogDetailPage(product._id)">
                  <span class="text" v-html="highlightMatch(product.name)"></span>
                </div>
              </li>
            </ul>
            <span v-else class="no-results">No results</span>
          </div>
          <button v-if="filteredProducts.length >= 5" 
            class="view-all" 
            @click="goToCatalogPage">See all results {{ filteredProducts.length > 5 ? `(+${filteredProducts.length})` : '' }}</button>
        </div>
      </div>
      <!-- Центральное меню -->
      <ul class="client-menu">
        <li @click="removeActiveFromLink" class="client-list">
          <router-link class="client-link" :to="`/${currentCompanyCatalog}/catalog-page`" :class="{ activePage: $route.path === `/${currentCompanyCatalog}/catalog-page` }">Catalog</router-link>
        </li>
        <li @click="removeActiveFromLink" class="client-list">
          <router-link class="client-link" :class="{ activePage: $route.path === `/${currentCompanyCatalog}/about` }" :to="`/${currentCompanyCatalog}/about`">Contacts</router-link>
        </li>
      </ul>

      <!-- Иконки справа -->
      <div class="icons-wrapper d-flex align-items-center">
        
        <router-link v-if="!catalog_settings.catalogMode" :to="`/${currentCompanyCatalog}/basket`" class="basket-icon nav-icon">
          <img src="../assets/clients/busket.svg" alt="Basket" />
          <div v-if="countOrders > 0" class="bg-not d-flex align-items-center">
            <span>{{countOrders}}</span>
          </div>
        </router-link>

        <span v-if="!catalog_settings.catalogMode && !catalog_settings.foodMode" class="nav-icon3" style="text-decoration: none;">
          <router-link v-if="!isLogged" :to="`/${currentCompanyCatalog}/signin`">
            <img src="../assets/clients/login.svg" alt="Login" />
          </router-link>
          <router-link v-else :to="`/${currentCompanyCatalog}/client-account`">
            <div class="client-avatar">{{ user.name.split(' ')[0][0] }}</div>
          </router-link>
        </span>
      </div>


      <!-- Бургер-меню для мобильных устройств -->
      <div class="burger-menu" @click="toggleMobileMenu">
        <img src="../assets/img/more.svg" alt="Menu" />
      </div>

      <!-- Мобильное меню -->
      <div class="mobile-menu" :class="{ 'active': isMobileMenuOpen }">
        <div class="mobile-menu-header">
          <span class="close-menu" @click="toggleMobileMenu">&times;</span>
        </div>
        <ul class="mobile-menu-list">
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/catalog-page`">Catalog</router-link>
          </li>
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/about`">Contacts</router-link>
          </li>
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/basket`">Basket</router-link>
          </li>
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/client-account`">Account</router-link>
          </li>
          <!-- <li>
            <router-link :to="`/${currentCompanyCatalog}/catalog-page`">
              <img src="../assets/clients/search.svg" alt="Search" /> Search
            </router-link>
          </li>
          <li v-if="!catalog_settings.catalogMode && !catalog_settings.foodMode">
            <router-link v-if="!isLogged" :to="`/${currentCompanyCatalog}/signin`">
              <img src="../assets/clients/login.svg" alt="Login" /> Login
            </router-link>
            <router-link v-else :to="`/${currentCompanyCatalog}/client-account`">
              <img src="../assets/clients/login.svg" alt="My Account" /> My Account
            </router-link>
          </li>
          <li v-if="!catalog_settings.catalogMode">
            <router-link :to="`/${currentCompanyCatalog}/basket`">
              <img src="../assets/clients/busket.svg" alt="Basket" /> Basket
              <span v-if="countOrders > 0" class="badge">{{countOrders}}</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </nav>
  </div>

</template>

<script>
import $ from 'jquery';
export default {
  name: "Navbar",
  data(){
    return{
      show_mini_basket:false,
      isMobileMenuOpen: false,
      searchQuery: "",
      data: [],
      filteredProducts: [],
      isFocused: false,
      isModalOpen: false,
    }
  },
  computed:{
    //Contacts company
    companyAddresses(){
      return this.$store.getters['Catalog/getCompanyAddresses'];
    },
    limitedFilteredProducts() {
      return this.filteredProducts.slice(0, 5);
    },

    user(){
      console.log("USER",this.$store.getters['Client/getUser']);
      return this.$store.getters['Client/getUser'];
    },
  // ...mapGetters(["Orders/countOrders" ,"Orders/shoppingCart"]),
    company_url_basket(){
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
    countOrders(){
      if(this.currentCompanyCatalog!==this.company_url_basket){
        return 0;
      }
      return this.$store.getters['Orders/countOrders'];

    },
    shoppingCart(){

      if(this.currentCompanyCatalog!==this.company_url_basket){
        return [];
      }
      return this.$store.state.Orders.shoppingCart;
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    isLogged(){
      return this.$store.getters['Client/getUserStatus'];
    },
    server(){
      return this.$server;
    },

  },
  methods:{
    goToCatalogPage() {
      this.$router.push({
        path: `/${this.currentCompanyCatalog}/catalog-page`,
        query: {
          searchText: this.searchQuery,
        }
      });
      this.searchQuery = '';
    },
    goToCatalogDetailPage(id) {
      this.$router.push(`/${this.currentCompanyCatalog}/catalog-detail/${id}`);
      this.searchQuery = '';
    },
    async getProducts() {
    try {
      const options = {
        headers: { "x-client-url": this.currentCompanyCatalog },
        params: {
          "limit": 10000,
        },
      };
      const response = await this.axios.get(this.url("getClientProducts"), options);
      this.data = response.data.objects;
      console.log(this.data)
    } catch (error) {
      console.error("Ошибка при загрузке продуктов:", error);
    }
  },
  filterProducts() {
    if (!this.data || !Array.isArray(this.data) || !this.searchQuery.trim()) {
      this.filteredProducts = [];
      return;
    }

    const search = this.searchQuery.trim().toLowerCase();

    this.filteredProducts = this.data.filter(product =>
      product?.name?.toLowerCase().includes(search)
    );
    // console.log(this.filteredProducts)
  },
  handleClickOutside(event) {
    const searchResultsWrapper = this.$refs.searchResultsWrapper;
    const searchInput = this.$refs.searchInput;

    if (
      searchResultsWrapper &&
      !searchResultsWrapper.contains(event.target) &&
      searchInput &&
      !searchInput.contains(event.target)
    ) {
      this.filteredProducts = [];
      this.searchQuery = '';
    }
  },
  highlightMatch(text) {
    if (!this.searchQuery) return text;
    const search = this.searchQuery.trim();
    const regex = new RegExp(`(${search})`, "gi");
    return text.replace(regex, `<strong class="highlight">$1</strong>`);
  },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      document.body.style.overflow = this.isMobileMenuOpen ? 'hidden' : '';
    },
    removeActiveFromLink(){
      // console.log("removeActiveFromLink","imp");
      if (this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false;
        document.body.style.overflow = '';
      }
    },
    removeActive(){
      console.log("removeActive","imp");
      $('.menu-wrapper').removeClass('active')
      setTimeout(()=>{
        $('.backdrop-menu').removeClass('active')
      },400);
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    showNavbar(){
      console.log("showNavbar","imp");
      $('.menu-wrapper').addClass('active')
      $('.backdrop-menu').addClass('active')
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },
    logout(){
      this.$store.dispatch("Client/logout");
      this.$store.dispatch("Orders/clearAll");
      this.$router.push({ path: `/${this.currentCompanyCatalog}`});
    },
    close_drop(){
        $('.basket-hover').css('visibility', 'hidden');
    },
    mouser(){
      $('.basket-hover').css('visibility', 'visible');
    },
    mouser_leave(){
      $('.basket-hover').css('visibility', 'hidden');
    }
  },
  created() {
    if(this.user) {
      this.socket.on("sendingHey", function (data) {
        $('#orderStatus').modal('show');
        let text = `Order #${data.code} is ${data.status}`;
        $('.orderStatusText').text(text);
      });
    }
  },
  mounted() {
    this.getProducts(); 
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  beforeUnmount() {
  // Убираем обработчик при уничтожении компонента (переход с beforeDestroy на beforeUnmount)
  document.removeEventListener('mousedown', this.handleClickOutside);
},
  watch: {
    searchQuery() {
      this.filterProducts();
    }
  }
}
</script>

<style scoped>


@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
.search-input {
  display: none; /* Скрываем по умолчанию */
  border: 1px solid #F3F3F6;
  text-decoration: none;
}
.search-input:hover {
  border: 1px solid #d9d9da;
}
@media (max-width: 1200px) {
  .search-input {
    height: auto;
  }
}
.text{
  text-decoration: none;
}

@media(min-width:770px){
  .search-input {
    background: #F3F3F6;
    border-radius: 100px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8%;
    width: 602px;
  }
  .search-input input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    margin-left: 10px;
  }
}
@media(max-width:770px){
  .search-input {
    background: #F3F3F6;
    border-radius: 20px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    width: 75%;
  }
  .search-input input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    margin-left: 10px;
  }
}
@media(max-width:470px){
  .search-input {
    background: #F3F3F6;
    border-radius: 20px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    /* margin-left: 8%; */
    width: 67%;
  }
  .search-input input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    margin-left: 10px;
  }
}
.search-input input::placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.router-link {
  text-decoration: none;
}
.search-input input {
  transition: border 0.3s ease-in-out;
}

.search-input input:focus {
  border: none;
}

.search-input:focus-within {
  border: 1px solid #616CF5;
}

.search-results-wrapper {
  position: absolute;
  background: white;
  border-radius: 15px;
  border: 1px solid #D8D8D8;
  width: 602px;
  z-index: 9999;
  margin-left: -5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  /* border: 1px solid red; */
}
@media (min-width: 1200px) {
  .search-results-wrapper {
    top: 80px !important;
  }
}
@media(max-width:1200px){
  .search-results-wrapper {
    /* position: absolute; */
    background: white;
    border-radius: 15px;
    border: 1px solid #D8D8D8;
    width: 75%;
    z-index: 9999;
    margin-top: 45px;
    margin-left: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    /* margin-left: 0; */
    left: 10%;
  }
}
@media(max-width:410px){
  .search-results-wrapper {
    width: 67%;
    margin-left: 25px;
  }
}
@media((min-width:410px) and (max-width:470px)){
  .search-results-wrapper {
    width: 63%;
    margin-left: 40px;
  }
}
@media((min-width:470px) and (max-width:575px)){
  .search-results-wrapper {
    width: 71%;
    margin-left: 23px;
  }
}
@media((min-width:360px) and (max-width:410px)){
  .search-results-wrapper {
    width: 62%;
    margin-left: 35px;
  }
}
@media(max-width:360px){
  .search-results-wrapper {
    width: 61%;
    margin-left: 35px;
  }
}
.search-results {
  max-height: 210px;
  min-height: 55px;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border: 1px solid red; */
}

.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 8px 14px;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  /* border: 1px solid red */

}

.search-results li:hover {
  background: #f0f0f0;
}

.highlight {
  font-weight: 400;
  color: black !important;
}

.view-all {
  background: #616CF5;
  border-radius: 20px;
  width: 98%;
  height: 32px;
  color: white;
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}

.view-all:hover {
  background: #4e5bd4;
}

.no-results {
  color: #737373;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 14px;
  display: flex;
  align-items: end;
  /* border: 1px solid red */
}

.nav-icon {
  margin-right: 15px;
  position: relative;
}
.nav-icon3 {
  width: 56px;
  height: 56px;
  /* border: 1px solid red; */
}
.nav-icon3 a {
  text-decoration: none !important;
}
.nav-icon2 {
  width: 44px;
  height: 42px;
  border-radius: 50%;
  background: #616CF5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* border: 1px solid red; */
}
@media (max-width: 1200px) {
  .nav-icon2 {
    width: 38px;
    height: 34px;
  }
}
@media (max-width: 350px) {
  .nav-icon2 {
    width: 33px;
    height: 28px;
  }  
}
@media (min-width: 351px) and (max-width: 500px) {
  .nav-icon2 {
    width: 32px;
    height: 28px;
  }  
}

.nav-icon2 img {
  width: 65%; /* Это заставит изображение заполнить контейнер */
  height: 65%; /* Заставит изображение быть одинаковым с размерами контейнера */
  object-fit: contain; /* Обеспечит сохранение пропорций изображения */
}
.client-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #F3F3F6;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #727272;
  font-size: 30px;
  text-decoration: none;
  /* border: 1px solid red; */
}

.nav-icon:last-child {
  margin-right: 0; /* Убираем отступ у последнего элемента */
}

.backdrop-menu.active{
  display: block;
}
.backdrop-menu{
  display: none;
  width: 100%;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  background: #000;
  opacity:0.5;
  z-index:99;
}
.contact{
  position: absolute;
  bottom: 20px;
  left:20px;
  border-top: 1px solid #e7e7e7;
  width: 88%;
  padding-top: 20px;
  display:none;
}
/* .router-link-active{
  background: none;
} */
.contact-title{
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
}
.footer-info{
  margin-bottom: 10px;
  display: block;
  color:#484848;
}
.list-span{
  margin-right: 40px;
}
.web-catalog-logo{
  height:45px;
}
.footer-info img{
  margin-right: 10px;
}
.footer-info a, .footer-info a:hover{
  color:#484848;
  text-decoration: none;
}

.log-out{
  display: none;
}

.close-nav{
  width: 24px;
  height: 24px;
}

.menu-wrapper.active{
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index:9999;
  top: 0;
  left: 0;
  background: #fafafa;
  padding: 0 20px;
  transition:.4s;
  overflow:hidden;


}
.mobile-basket ,.basket-menu{
  display:none;

}
.mobile-basket{
  width: 26px;
  height: 26px;
}
.basket-menu{
  position: relative;
  padding: 5px;

}
.basket-menu img{
  width: 24px;
  height: 24px;
}
.basket-menu .bg-not{
  position: absolute;
  top:0;
  right:0;


}
.menu-wrapper .mobile-header{
  display:none !important;
}
.menu-wrapper.active .mobile-header{
  display: flex !important;
}
.menu-wrapper.active .client-menu {
  margin: auto;
  display: block;
  padding: 0;
}
.menu-wrapper.active .client-list{
  margin-bottom: 20px;
  margin-right: 0;
}
.client-list img{
  width: 20px;
  height: 20px;
}
.burger{
  display: none;
}

.navigation {
  height: 92px;
  border-bottom: 1px solid #E4E4E4;
  justify-content: space-between;
  /* position: relative; */
  /* z-index: 999; */
  /* border: 1px solid red; */
}

.brand-navbar{
  color: #222222;
  font-size: 18px;
  text-decoration: none;
  font-weight:normal ;
}
.brand-navbar span{
  font-weight: 600;
}

.client-menu{
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 0;
  margin-right: 15px;
}
.client-list{
  list-style-type:none ;
  margin: 0 20px;
  cursor: pointer;
}
.client-list.hoverBasket{
  margin-right: 0;
}
/* .client-list img{
  margin-right: 10px;
} */
.client-link{
  font-weight: bold;
  color: #222222;
  text-decoration: none;
  font-size: 14px;
}
.client-link:hover{
  color: #616cf5;
}
.activePage {
  color: #616cf5;
}

.bg-not{
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background: #616cf5;
  margin-left: 7px;
  color:#fff;
  border-radius: 50%;
  justify-content: center;
  font-size:10px;
  align-items:center;
}

.basket-title{
  width: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
}
.basket-hover{
  background: #FFFFFF;
  width: 20rem;
  box-shadow: 2px 11px 35px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-bottom:24px;
  transition: .3s;
  position: absolute;
  right:0;
  top: 100px;
  margin: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;

}
.hoverBasket:hover .basket-hover{
  opacity: 1;
  top: 30px;
  visibility: visible;
}

.basket-hover .save{
  width:100%;
  margin-top: 20px;
}
.basket-header{
  padding-bottom:10px;
}
.basket-header h5{
  color:#222;
  font-weight: 600;
  font-size: 20px;
}
.basket-img{
  height:60px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.basket-img img{
  width: 100%;
  height: 100%;
}
.basket-title{
  font-size:16px;
  font-weight: normal;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:179px ;
}
.basket-code{
  color: #B0B0B0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.basket-price{
  font-size: 18px;
  font-weight: normal;
}
.scroll-basket{
  max-height: 250px;
  overflow-y: auto;
}
.empty_basket_title{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #B0B0B0;
}
.catalog_logo{
  background-repeat: no-repeat;
  max-height: 55px;
  height: 45px;
  max-width: 45px;
  width: 55px;
  background-size: contain;
  background-position: center;
}
@media(min-width:1200px){
  .client-container{
    width: calc(100vw - 240px);
  }
}




.burger-menu {
  display: none;
  cursor: pointer;
  /* position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); */
}

.burger-menu img {
  width: 30px;
  height: 30px;
}

.burger-menu span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 11%;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.mobile-menu.active {
  right: 0;
}

.mobile-menu-header {
  padding: 20px;
  display: flex;
  justify-content: end;
}

.close-menu {
  font-size: 30px;
  cursor: pointer;
}

.mobile-menu-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobile-menu-list li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-list a {
  color: #333;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.mobile-menu-list img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.badge {
  background-color: #616cf5;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}

@media (max-width: 768px) {

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .burger-menu {
    display: flex;
  }

  .mobile-menu {
    display: block;
  }

  .client-menu, 
  .icons-wrapper {
    display: none !important;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    left: -9999px;
    /* border: 1px solid red; */
  }

  .navigation {
    justify-content: space-between;
    padding: 10px 0;
  }

  .brand-navbar {
    font-size: 16px;
  }

  .web-catalog-logo {
    height: 35px;
  }
}

</style>