<template>
    <div class="skeleton-product">
      <div class="skeleton-image"></div>
      <div class="skeleton-text"></div>
      <div class="skeleton-text"></div>
      <div class="skeleton-text short"></div>
      <div class="skeleton-text tall"></div>
    </div>
  </template>
  
  <style scoped>
  .skeleton-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: auto;
    background: transparent;
    border-radius: 8px;
    padding: 10px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 175px;
    background: linear-gradient(90deg, #F3F3F6 25%, #f0f0f0 50%, #F3F3F6 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 6px;
    border-radius: 15px;
  }
  
  .skeleton-text {
    width: 100%;
    height: 15px;
    background: linear-gradient(90deg, #F3F3F6 25%, #f0f0f0 50%, #F3F3F6 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 30px;
  }
  
  .skeleton-text.short {
    width: 40%;
    height: 19px;
    border-radius: 30px;
  }
  .skeleton-text.tall {
    width: 60%;
    height: 28px;
    border-radius: 30px;
  }
  
  @keyframes loading {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  </style>
  