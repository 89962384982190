<template>
  <div>
  <div>
    <div class="product-carousel-wrapper">
      <img src="../../../assets/clients/arrow-right.svg" class="carousel-arrow left" @click="scrollProducts('left')">
      <div class="product-carousel">
        <div class="carousel-container" ref="carouselContainer">
          <div class="product-box" :id="`catalog${product._id}`" v-for="(product) in catalog" :key="product._id">
            <div>
              <div @click="$router.push({ path:`/${currentCompanyCatalog}/catalog-detail/${product._id}`})" class="product-img">
                <img v-if="!product.error" :src="server+'/'+product.img" @error="product.error=true">
                <img v-else src="../../../assets/icons/noProd.svg">
              </div>
              <div class="product-add">
                <h2
                class="long-text tool-tip" 
                style="overflow: hidden; text-overflow: ellipsis; margin-bottom: 10px;" 
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                :title="product.name"
                >{{product.name}}</h2>
                <span v-if="checkDates(product.promoStart, product.promoEnd)" :class="'discountPrice'">
                  {{ product.promoPrice }} {{ catalog_settings.currency }}
                </span>

                <span :class="{lineThrough: checkDates(product.promoStart, product.promoEnd)}">
                  {{ product.price }} {{ catalog_settings.currency }}
                </span>
              </div>
              <button class="add-to-card" :class="{ 'not-available': product.quantity === 0 }" @click="selectProduct(product._id)">{{ product.quantity === 0 ? 'Not Available' : 'Add to cart' }}</button>
            </div>
          </div>
        </div>
      </div>
      <img src="../../../assets/clients/left-arrow.svg" class="carousel-arrow right" @click="scrollProducts('right')">
    </div>
  </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Tooltip } from 'bootstrap'

export default {
  name: "ClientCatalogItem",
  props: ['catalog'],
  data() {
    return {
      today: new Date(),
      isMobile: false,
    }
  },
  computed: {
    userDiscountStatus() {
      return this.$store.getters['Client/getUserDiscountStatus'];
    },
    company_url_basket() {
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    server() {
      return this.$server;
    },
    catalog_settings() {
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    checkDates(start, end) {
      if (!start || !end) {
        return false;
      }
      let itsPromo = false;
      let startDate = new Date(start);
      let endDate = new Date(end);
      if (startDate <= this.today && endDate >= this.today) {
        itsPromo = true;
      }
      return itsPromo;
    },
    addToCart(id) {
      if (this.company_url_basket !== this.$route.params.bekon) {
        this.$store.dispatch("Orders/clearAll");
      }

      let that = this;
      let cart_object = {
        size: {},
        client_status_discount: this.userDiscountStatus.discount_percentage || 0,
        product: {},
        isDiscounted: false,
        quantity: 1,
        promocode: '',
        discount_percent: 0,
        discount_percent_sum: 0,
        discount_sum: 0,
        current_price: 0,
      }
      const order = this.catalog.filter((el) => el._id === id);
      cart_object.product = order ? order[0] : null;

      if (cart_object.product.hasMultipleTypes) {
        $('#selectSizeModalClientCatalogItem').modal('show');
        return;
      }

      let current_price = order ? order[0].price : 0;
      let old_price = order ? order[0].price : 0;
      if (this.checkDates(cart_object.product.promoStart, cart_object.product.promoEnd)) {
        current_price = order ? order[0].promoPrice : 0;
        let discount_sum = old_price - current_price;
        cart_object.discount_sum = discount_sum > 0 ? discount_sum : 0;
        cart_object.isDiscounted = true;
      } else if (cart_object.client_status_discount > 0) {
        let disc = (current_price * (cart_object.client_status_discount / 100)).toFixed(2);
        current_price = (current_price - disc).toFixed(2);
        cart_object.discount_percent_sum = disc;
      }
      cart_object.current_price = current_price;
      this.$store.dispatch('Orders/addToCart', cart_object);
      this.$store.dispatch('Orders/setCompany_url_basket', that.$route.params.bekon);
      let version = new Date();
      this.$store.dispatch("Orders/setVersion", version);
    },
    selectProduct(id) {
      this.$router.push({ path: `/${this.currentCompanyCatalog}/catalog-detail/${id}` });
    },
    scrollProducts(direction) {
      const container = this.$refs.carouselContainer;
      const scrollAmount = 200;
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    }
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);

    this.$nextTick(() => {
      // Инициализация tooltip для всех элементов с атрибутом data-bs-toggle="tooltip"
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
        new Tooltip(el);
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  watch: {
    catalog: {
      handler() {
        this.$nextTick(() => {
          document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
            new Tooltip(el);
          });
        });
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.add-padding {
  padding-right: 15px;
}

.long-text {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  width: 200px;
  /* border: 1px solid red */
}

.product-img {
  margin-bottom: 16px;
  height: 215px;
  width: 215px;
  /* border: 1px solid red; */
  border-radius: 15px;
}
@media (max-width: 1200px) {
  .product-img {
    margin-bottom: 16px;
    height: 176px;
    width: 175px;
    /* border: 1px solid red; */
    border-radius: 15px;
  }
}

.product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.product-add {
  max-width: 100%; /* Увеличиваем ширину до 100% */
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.add-to-card{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border:none;
  background: #616CF5;
  border: 1px solid #616CF5;
  border-radius: 100px;
  width: 135px;
  height: 28px;
}
.add-to-card:hover{
  background: #616CF5D9;
  /* border: 1px solid #616CF5; */
  transition:.3s;
}
.not-available {
  cursor: not-allowed;
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
  font-size: 12px !important;
  height: 28px;
}
@media (max-width: 1200px) {
  .not-available {
    height: 28px;
  }
}
.not-available:hover{
  color: #898989;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
}

.product-box {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  border: 0.5px solid transparent;
  margin: 0 auto;
  /* border: 1px solid red; */
}

.product-box:hover {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 15px;
  transition: 0.3s;
}

.product-add span {
  font-size: 16px;
}

.product-add h2 {
  margin-bottom: 5px;
}

.product-add h2, .product-add h3 {
  font-size: 14px;
}

.product-add h3 {
  font-weight: normal;
  color: #b0b0b0;
  margin: 5px 0;
}

.lineThrough {
  text-decoration: line-through;
  font-size: 11px !important;
  font-weight: 600;
}
.discountPrice {
  color: #BF2C2C;
  font-size: 16px;
  font-weight: 700;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .product-box:hover {
    border: none;
  }

  .product-box {
    padding-right: 0;
  }
}

@media (max-width: 380px) {
  .product-img img {
    height: 170px;
  }

  .product-img {
    margin-bottom: 0;
  }

  .add-to-card {
    padding: 0.3rem 30px;
  }
}

@media (max-width: 330px) {
  .product-img img {
    height: 138px;
  }
}

.product-carousel-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.product-carousel {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 0 1px;
}

.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-arrow {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
}

.carousel-arrow.left {
  left: -40px;
}

.carousel-arrow.right {
  right: -40px;
}


@media (max-width: 768px) {
  .category-carousel-wrapper {
    padding: 0 10px;
  }

  .carousel-arrow {
    display: none;
  }

  .category-item {
    width: 120px;
    height: 160px;
    padding: 10px;
  }

  .category-image {
    height: 90px;
    width: 100px;
    margin-bottom: 5px; /* Уменьшенный отступ для мобильных */
  }

  .category-name {
    font-size: 12px;
    height: 35px; /* Уменьшенная высота для мобильных */
  }
}
</style>