<template>
    <div class="container product-detail">
      <div class="skeleton skeleton-header"></div>
      <div class="row">
        <!-- Левая колонка (Галерея) -->
        <div class="col-lg-6">
          <div class="skeleton skeleton-main-image"></div>
          <div class="thumbnail-gallery" style="display: flex; gap: 10px; margin-top: 20px;">
            <div v-for="n in 4" :key="n" class="skeleton skeleton-thumbnail"></div>
          </div>
        </div>
  
        <!-- Правая колонка (Информация) -->
        <div class="col-lg-5 product-info">
          <div class="skeleton skeleton-title"></div>
          <div class="skeleton skeleton-text"></div>
          <div class="skeleton skeleton-text short"></div>
          <div class="flexing">
            <div class="skeleton skeleton-price"></div>
            <div class="skeleton skeleton-button"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  /* Общие стили для скелетона */
  .skeleton {
    background: #f3f3f6;
    background: linear-gradient(90deg, #e0e0e0 25%, #f3f3f6 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 8px;
  }
  .skeleton-header {
    width: 30%;
    height: 25px;
    border-radius: 40px;
    margin-bottom: 30px;
  }
  @media (min-width: 992px) {
    .product-info{
      margin-top: 18px;
      /* border: 1px solid red; */
    }
  }
  @media (max-width: 992px) {
    .product-info{
      margin-top: 15px;
    }
  }
  .flexing{
    display: flex;
    gap: 20px;
    margin-top: 375px;
  }
  @media (max-width: 992px) {
    .flexing{
      margin-top: 20px;
    }
  }
  
  /* Левая часть (Галерея) */
  .skeleton-main-image {
    width: 97%;
    height: 560px;
    border-radius: 20px;
  }
  @media (max-width: 992px) {
    .skeleton-main-image {
      width: 100%;
      height: 250px;
    }
  }
  
  .skeleton-thumbnail {
    width: 80px;
    height: 80px;
    border-radius: 6px;
  }
  
  /* Правая часть (Информация) */
  .skeleton-title {
    width: 70%;
    height: 24px;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  
  .skeleton-text {
    width: 100%;
    height: 24px;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  
  .skeleton-text.short {
    width: 25%;
    height: 24px;
    border-radius: 40px;
  }
  
  .skeleton-price {
    width: 30%;
    height: 50px;
    border-radius: 40px;
    margin-bottom: 15px;
  }
  
  .skeleton-button {
    width: 50%;
    height: 50px;
    border-radius: 40px;
  }
  
  @keyframes loading {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  </style>
  