<template>
  <div>
    <div class="about-con">
      <h1 class="about-title">About Us</h1>
      <p class="client-paragraph">{{catalog_settings.description}}</p>
    </div>
    <div v-if="catalog_settings.banner" class="main-box" v-bind:style="{ backgroundImage: 'url(' + server+'/'+catalog_settings.banner + ')' }"></div>
    <div class="addresses">
      <div class="text-center">
        <h3>Address</h3>
        <div>
          <p v-if="catalog_settings?.branches?.length">{{ catalog_settings.branches[0].address }}</p>
          <p v-if="catalog_settings?.branches?.length">Mn - Fr {{catalog_settings.branches[0].monday.start}}-{{catalog_settings.branches[0].monday.end}}</p>
          <p v-if="catalog_settings?.branches?.length">Sd {{catalog_settings.branches[0].sunday.start}}-{{catalog_settings.branches[0].sunday.end}}</p>
        </div>
      </div>
      <div class="text-center">
        <h3>Links</h3>
        <div class="contact-icon">
          <a :href="catalog_settings.facebook" target="_blank"><img src="../../../assets/clients/Facebook.svg"></a>
          <a :href="catalog_settings.instagram" target="_blank"><img src="../../../assets/clients/Instagram.svg"></a>
        </div>
      </div>
      <div class="text-center">
        <h3>Contact</h3>
        <div>
          <p v-if="catalog_settings?.branches[0]?.phone">{{catalog_settings.branches[0].phone}}</p>
          <p v-if="catalog_settings?.branches[0]?.phone2">{{catalog_settings.branches[0].phone2}}</p>
          <p class="mb-0">{{catalog_settings.email}}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Карта -->
      <div class="map-container">
        <LMap 
        v-if="coordinates"
        :zoom="15" 
        :center="coordinates"
        style="height: 100%; width: 100%;"
        class="map"
        @ready="console.log('Map is rendering')">
          <LTileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
          <LMarker :lat-lng="coordinates"></LMarker>
        </LMap>
      </div>

      <div class="additional-con">
        <h3 class="additional">Additional Address</h3>
        <div v-if="catalog_settings.branches" class="branches-container">
          <div v-for="(branch, index) in catalog_settings.branches" :key="index" class="branch-card">
            <p>{{ branch.address }}</p>
            <p>Mn - Fr: {{ branch.monday.start }} - {{ branch.friday.end }}</p>
            <p>Sd: {{ branch.saturday.active ? branch.saturday.start + ' - ' + branch.saturday.end : 'Closed' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "About",
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data(){
    return{
      coordinates: null
    }
  },

  computed:{
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    currentCompanyCatalogStorage() {
      return this.$store.getters['Catalog/getCompany_ur'];
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },

    companyAddresses() {
      return this.catalog_settings.branches || [];
    },
    server(){
      return this.$server;
    },
    branch() {
  if (!this.catalog_settings || !Array.isArray(this.catalog_settings.branches)) {
    return null;
  }
  return this.catalog_settings.branches.length ? this.catalog_settings.branches[0] : null;
}

  },

  watch: {
    branch: {
      immediate: true,
      handler(newBranch) {
        if (newBranch?.latitude && newBranch?.longitude) {
          this.coordinates = [Number(newBranch.longitude), Number(newBranch.latitude)];
        } else {
          this.coordinates = [42.8746, 74.5698]; // Бишкек по умолчанию
        }
      }
    }
  },
  beforeCreate() {
    (async () => {
      const options = {
        headers: {"x-client-url": this.$route.params.bekon}
      }
      await this.axios.get(this.url('getCatalogSettings'),options)
          .then((response) => {
            // console.log("API response:", response.data.branches);
            let settings = response.data.object;
            this.$store.dispatch("Catalog/setCompany_addresses",response.data.branches);
            this.$store.dispatch("Catalog/setCompany_delivery_options",response.data.deliveries);
            let catalog_settings={
              name:settings.name,
              currency:settings.currency,
              catalogMode:settings.catalogMode,
              foodMode:settings.foodMode,
              share_points_status:response.data.share_points_status,
              email:settings.email,
              banner:settings.banner,
              logo:settings.logo,
              description:settings.description,
              welcome:settings.welcome,
              delivery:settings.delivery,
              deliveryDescription:settings.deliveryDescription,
              telegram:settings.telegram,
              facebook:settings.facebook,
              instagram:settings.instagram,
              whatsapp:settings.whatsapp,
              website:settings.website,
              branches:response.data.branches,
            }
            this.$store.dispatch("Catalog/setCatalog_settings",catalog_settings);
            // $('.overlay_404').show();
          }).catch(function (error){
            if (error.response) {
              console.log('eeeeeeeeeeeeee',error.response)
              // let err_page = `
              // <div>--->404</div>
              // `
              // $('.overlay_404').html(err_page).show();
            }
          })
    })().catch(err => {
      console.error(err);
    });
  },

}
</script>

<style scoped>
.about-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 50%;
  margin: 0px auto;
  margin-bottom: 70px;
  padding: 0;
  /* border: 1px solid red; */
}
.addresses {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
  margin-top: 70px;
}
@media(max-width: 1200px) {
  .addresses {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
  }
  .addresses h3 {
    font-size: 20px;
  }
  .addresses p {
    font-size: 14px;
    font-weight: 400;
  }
  .map {
    border-radius: 9px !important;
  }
  .additional {
    font-size: 20px !important;
    text-align: center;
  }
  .about-con {
    width: 100%;
    margin-bottom: 30px;
  }
}
.map {
  border-radius: 29px;
}
h3{
  color: #222222;
  font-size: 35px;
  font-weight: 700;
}
.addresses p{
  color: #222222;
  font-size: 16px;
  font-weight: 400;
}
.text-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.map-container {
  width: 100%;
  height: 431px;
  margin-top: 70px;
  margin-bottom: 70px;
  border-radius: 29px;
}
@media(max-width: 1200px) {
  .map-container {
    height: 168px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .branches-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Автоматически создаёт колонки */
    gap: 25px;
    justify-content: center;
    /* border: 1px solid red; */
  }
  .branch-card p {
    text-align: center;
  }
  .about-title {
    font-size: 30px !important;
    margin: 10px 0 !important;
  }
}
.branches-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Автоматически создаёт колонки */
  gap: 16px;
}

.branch-card {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}
.branch-card p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  /* border: 1px solid red; */
}





@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
.client-paragraph{
  font-size: 15px;
  font-weight: 400;
  color: #606877;
  text-align: center;
  /* border: 1px solid red; */
  margin: 0;
}
.relatives{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;justify-content: center;
  flex-direction: column;
  color:#fff;
}
.main-box{
  height: 522px;
  background: url('../../../assets/clients/main-box.svg')no-repeat center;
  position: relative;
  border-radius: 5px;
  background-size:cover;
}
@media (max-width: 1200px) {
  .main-box{
    height: 135px !important;
  }
}
.about-title{
  margin: 30px 0;
  font-size: 100px;
  font-weight: 700;
  color: #000000;
  /* border: 1px solid red; */
}
.catalog-btn{
  margin-bottom: 40px;
}
.shop-name{
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}
.contact-title{
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
}
.contact-box{
  background: #F8F9FF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box img{
  margin-right: 10px;
}
.contact-box a{
  color:#616CF5;
}
.contact-box a:hover{
  text-decoration: none;
}
.contact-box p{
  margin-bottom: 9px;
}
.contact-address{
  color: #606877;
  margin-bottom: 5px;
}
.contact-time{
  color:#616CF5;
}
.contact-icon{
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  gap: 10px;
}
@media(max-width:481px) {
  .main-box{
    height: 270px;
  }
}
</style>