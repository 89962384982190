<template>
  <footer class="container">
    <div class=" d-flex justify-content-between align-items-center">
      <div class="footer-logo">
        <img class="web-catalog-logo" v-if="catalog_settings.logo" :src="server + '/' + catalog_settings.logo">
        <span v-else>
          Company Logo
        </span>
      </div>
    </div>
    <div class="footer-content">
      <div class="footer-column">
        <p style="">{{ catalog_settings.description }}</p>
        <div class="footer-mobile2">
          <p style="display: flex; gap: 10px; justify-content: start; align-items: center;"><img src="../../../assets/clients/Call.svg"><a href="/">{{companyAddresses[0] ? companyAddresses[0].phone : "+996771236478"}}</a></p>
          <p style="display: flex; gap: 10px; justify-content: start; align-items: center;"><img src="../../../assets/clients/Message.svg"><a href="/">{{catalog_settings.email || "example@gmail.com"}}</a></p>
        </div>
      </div>
      <div class="pages">
        <div class="footer-column2">
          <div>
            <h3>Page</h3>
            <p style="cursor: pointer" @click="$router.push(`/${currentCompanyCatalog}`)">Home</p>
            <p style="cursor: pointer" @click="$router.push(`/${currentCompanyCatalog}/about`)">About us</p>
            <p style="cursor: pointer" @click="$router.push(`/${currentCompanyCatalog}/catalog-page`)">Catalog</p>
            <p style="cursor: pointer" @click="$router.push(`/${currentCompanyCatalog}/about`)">Contact</p>
          </div>
        </div>
        <div class="footer-columnn">
          <div>
            <h3>Address</h3>
            <p>{{companyAddresses[0] ? companyAddresses[0].address : "Bishkek, Kyrgyzstan"}}</p>
            <p>{{ companyAddresses[0] ? companyAddresses[0].monday.start : "Bishkek, Kyrgyzstan" }} - {{ companyAddresses[0] ? companyAddresses[0].monday.end : "Bishkek, Kyrgyzstan" }} Every day</p>
          </div>
        </div>
      </div>
      <div class="footer-column footer-mobile">
        <div>
          <h3>Contacts</h3>
          <p style="display: flex; gap: 10px; justify-content: start; align-items: center;"><img src="../../../assets/clients/Call.svg"><a href="/">{{companyAddresses[0] ? companyAddresses[0].phone : "+996771236478"}}</a></p>
          <p style="display: flex; gap: 10px; justify-content: start; align-items: center;"><img src="../../../assets/clients/Message.svg"><a href="/">{{catalog_settings.email || "example@gmail.com"}}</a></p>
        </div>
      </div>
    </div>

    <!-- <hr> -->
    <div class="footer-bottom  d-flex justify-content-between align-items-center">
      <div class="footer-payment">
        <img src="../../../assets/clients/visa_logo.svg" alt="Visa">
        <img src="../../../assets/clients/mastercard_logo.svg" alt="Mastercard">
      </div>
      <div class="footer-poweredby">
        <div class="footer-logo-small">
          <img src="../../../assets/clients/logo.svg" alt="Small Logo">
        </div>
        <div @click="$router.push('https://loygift.com')" style="cursor: pointer">
          <span>powered by <strong>loygfit</strong></span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    catalog_settings() {
      console.log(this.catalog_settings);
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    companyAddresses() {
      console.log(this.companyAddresses);
      return this.$store.getters['Catalog/getCompanyAddresses'];
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    server(){
      return this.$server;
    },
  },
}
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
footer {
  background: #272727;
  padding: 20px 0;
  margin-top: 100px;
}
.footer-logo-small img {
  height: 20px;
  width: 20px;
}
@media(max-width: 900px) {
  .footer-logo {
    margin: 20px 0;
  }
}
@media(min-width: 900px) {
  .footer-logo {
    margin: 70px 0;
  }
}
.footer-logo img {
  height: 50px;
  width: 50px;
}
.footer-content {
  display: flex;
  gap: 150px;
  padding: 20px 0;
}
.pages {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.footer-column {
  /* flex: 1; */
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  color: #fff;
  /* border: 1px solid red; */
}
@media(min-width: 1200px) {
  .footer-mobile {
    display: none;
  }
}
@media(max-width: 1200px) {
  .footer-mobile2 {
    display: none;
  }
  .pages {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    /* border: 1px solid red; */
  }
  .footer-column {
    width: 100%;
  }
  .footer-content {
    display: flex;
    gap: 20px;
  }
}
@media(max-width: 1200px) {
  .footer-columnn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #fff;
    margin-top: 15px;
  }
}
@media(min-width: 1200px) {
  .footer-columnn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    color: #fff;
    /* border: 1px solid red; */
  }
}
.footer-column:last-child {
  margin-right: 0;
}
.footer-column2 {
  flex: 1;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #fff;
}
@media(max-width: 1200px) {
  .footer-column2 {
    flex: 1;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 15px;
    color: #fff;
  }
}
.footer-column h3  {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}
.footer-column p  {
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.footer-column2 h3  {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}
.footer-column2 p  {
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.footer-column2 p:hover {
  color: #bdbaba;
}
.footer-columnn h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}
.footer-columnn p {
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
a {
  text-decoration: none;
  color: #fff;
}
.footer-column img {
  margin-right: 10px;
}
.footer-bottom {
  padding: 20px 0;
  border-top: 1px solid #676767
}
.footer-payment img {
  max-height: 30px;
  margin-right: 10px;
}
.footer-poweredby {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}
@media(max-width: 640px) {
  .footer-content {
    flex-direction: column;
    padding: 20px 20px; /* Добавлены боковые отступы */
  }
  .footer-container {
    padding: 20px 20px; /* Добавлены боковые отступы */
  }
  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .footer-logo, .footer-logo-small {
    text-align: center;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    padding: 20px 15px; /* Добавлены боковые отступы */
  }
  .footer-payment {
    margin-bottom: 10px; /* Отступ снизу для разделения элементов */
  }
}
</style>