<template>
<div class="">
  <div class="container d-flex justify-content-between align-items-center mb-3" >
    <div style="font-weight: 700; font-size: 28px;">Category</div>
    <div class="all-link-container">
      <router-link class="view-all" :to="`/${currentCompanyCatalog}/catalog-page`">
        See all
      </router-link>
      <img src="../../../assets/clients/arrow-left.svg" class="arrow-icon">
      </div>
  </div>

  <div class="container category-carousel-container">
    <img 
        src="../../../assets/clients/arrow-right.svg" 
        class="carousel-arrow left" 
        @click="scrollCategories('left')" 
        v-if="!isMobile"
        alt="Стрелка влево"
      >
    <div class="category-carousel-wrapper">
      <!-- <img src="../../../assets/clients/arrow-right.svg" class="carousel-arrow left" @click="scrollCategories('left')" v-if="!isMobile"> -->
      <div class="category-carousel">
        <div class="carousel-container" ref="carouselContainer">
          <div v-for="category in listCategory" 
              :key="category._id" 
              class="category-item" 
              @click="router.push(`/${currentCompanyCatalog}/catalog-page?categoryId=${category._id}`)"
          >
            <div class="category-image">
              <img :src="getCategoryImage(category)" alt="Category Image">
            </div>
            <div class="category-name">{{ category.name }}</div>
          </div>
        </div>
      </div>
      <!-- <img src="../../../assets/clients/left-arrow.svg" class="carousel-arrow right" @click="scrollCategories('right')" v-if="!isMobile"> -->
    </div>

    <img 
        src="../../../assets/clients/left-arrow.svg" 
        class="carousel-arrow right" 
        @click="scrollCategories('right')" 
        v-if="!isMobile"
        alt="Стрелка вправо"
      >
  </div>
  

  <div class="container d-flex justify-content-between align-items-center mb-3" style="margin-top: 50px;">
    <div style="font-weight: 700; font-size: 28px;">Recommendations</div>
    <div class="all-link-container">
      <router-link class="view-all" :to="`/${currentCompanyCatalog}/catalog-page`">
        See all
      </router-link>
      <img src="../../../assets/clients/arrow-left.svg" class="arrow-icon">
      </div>
  </div>
  <div  id="products" class="container-prod">
    <div v-if="spinner">
      <Spinner/>
    </div>
    <div v-else>
      <ClientCatalogItem v-bind:catalog="filteredList"/>
    </div>
  </div>

  <div class="container">
    <div class="main-box" v-bind:style="{ backgroundImage: 'url(' + server+'/'+catalog_settings.banner + ')' }" >
      <div class="relatives">
        <div style="margin-left: 10%;">
          <h1 style="font-size: 35px; font-weight: bold;">{{catalog_settings.name || 'Company Name'}}</h1>
          <p style="font-size: 22px; font-weight: 400;">{{catalog_settings.welcome || 'Welcome to our thingy'}}</p>
          <button class="details-button">View more</button>
        </div>
      </div>
    </div>
  </div>

</div>

</template>

<script>
import $ from 'jquery';
import { useRoute, useRouter } from 'vue-router';
import ClientCatalogItem from "@/client/components/ClientCatalog/ClientCatalogItem";
import Spinner from "../../../components/Spinner";
const _ = require('lodash');
export default {
name: "Catalog",
  components:{
    ClientCatalogItem,
    Spinner
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  data(){
    return{
      showSortDrop:false,
      sortBy:"ascendingName",
      searchStatus:false,
      searchText:"",
      spinner:true,
      catalog:[],
      listCategory:[],
      filtered: 'all',
      selectedCategory:"all",
      from:0,
      to:1000,
      showCategory:'All',
      currentPage:1,
      numberOfPagesArray:[],
      isMobile: false,
    }
  },
  computed:{
    filteredList: function(){
      return  this.catalog
        // .filter(product => {
        //   if(this.filtered!=='all'){
        //     return product.category===this.filtered;
        //   }else{
        //     return product;
        //   }
        // })
        // .filter((product)=>{
        //   return product.price >= this.from && product.price <= this.to;
        // })
        // .filter((product)=>{
        //   return product.quantity > 0;
        // })
        // .filter((product)=>{
        //   return product.active === true;
        // })
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
  },
  methods: {
    catalogSearch(){
      // this.selectedCategory = "all";
      this.currentPage = 1;
      this.getProducts();
      this.searchStatus = true;
    },
    setCategory(id){
      this.selectedCategory = id;
      this.currentPage = 1;
      localStorage.setItem('currentCategory', id);
      this.getProducts();
      
      // Обновляем URL при выборе категории
      //his.router.push({ query: { ...this.route.query, categoryId: id } });
    },
    setPage(page){
      console.log(page)
      this.currentPage = page;
      localStorage.setItem('currentPage', page);
      this.getProducts();
    },
    displayFiltered(id){
        this.filtered = id
        this.listCategory.map(i=>{
          if(i._id == id){
            this.showCategory = i.name
          }
        })
        $('.showCategory').removeClass('active')

        $('.backdrop-filter').removeClass('active')
      $('body').css({'overflow':'auto'})
      },
    showFilterCategory(item){
      console.log("showFilterCategory");
      if(item==='category'){
        $('.showCategory').addClass('active')
      }
      else{
        $('.showFilter').addClass('active')
      }
      $('.backdrop-filter').addClass('active')
      $('body').css({'overflow':'hidden'})
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },

    removeFilterCategory(item){
      console.log("removeFilterCategory");
      if(item === 'category'){
        $('.showCategory').removeClass('active')

      }
      else{
        $('.showFilter').removeClass('active')
      }
      $('body').css({'overflow':'auto'})
      $('.backdrop-filter').removeClass('active')
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    getRangeValues() {
      const slider = $("#range-slider").data("ionRangeSlider");
      this.from = slider.result.from;
      this.to = slider.result.to;
      console.log(`getRangeValues call ${slider.result.from} ${slider.result.to}`)
    },
    rangeSlider() {
      // console.log("range slider call");
      $("#range-slider").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        prefix: this.catalog_settings.currency + " ",
        onChange: (data) => {
          console.log(`rangeSlider on change ${data.from}`)
          this.from = data.from;
          this.to = data.to
        }
      });
      $("#range-slider2").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        prefix: this.catalog_settings.currency + " ",
        onChange: (data) => {
          this.from = data.from;
          this.to = data.to
        }
      });
    },
    async  getProducts(){
      // console.log("getProducts call");
      this.spinner = true;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog},
        params: {
          "page":this.currentPage,
          "categoryId":this.selectedCategory,
          "min":this.from,
          "max":this.to,
          "searchText":this.searchText,
          "sortBy":this.sortBy,
        },
      }
       await this.axios.get(this.url('getClientProducts'),options)
         .then((response) => {
           this.catalog = response.data.objects;
           this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
           this.spinner = false;
         })
    },
    async  getProductsForPrice(){
      //yes its a copy of a function
      // console.log("getProductsForPrice_call");
      let that = this;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog},
        params: {
          "page":this.currentPage,
          "categoryId":this.selectedCategory,
          "min":this.from,
          "max":this.to,
        },
      }
      await this.axios.get(this.url('getClientProducts'),options)
        .then((response) => {
          // this.catalog = response.data.objects;
          // this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
          // this.spinner = false;
          this.from = response.data.minPrice;
          this.to = response.data.maxPrice;
          let instance = $("#range-slider").data("ionRangeSlider");
          instance.update({
            from: that.from,
            to:that.to,
            max:that.to,
          });
          let instance2 = $("#range-slider2").data("ionRangeSlider");
          instance2.update({
            from: that.from,
            to:that.to,
            max:that.to,
          });
        })
    },

    processCategories(categories) {
      const categoryMap = {};
      const processedCategories = [];

      // Создание словаря категорий для быстрого доступа
      categories.forEach(category => {
        categoryMap[category._id] = { ...category, level: 0, children: [] };
      });

      // Построение иерархии и определение уровня вложенности
      categories.forEach(category => {
        if (category.parent) {
          categoryMap[category.parent].children.push(categoryMap[category._id]);
          categoryMap[category._id].level = categoryMap[category.parent].level + 1;
        } else {
          processedCategories.push(categoryMap[category._id]);
        }
      });

      // Рекурсивная функция для флаттирования иерархии
      const flattenCategories = (categories) => {
        let flatList = [];
        categories.forEach(category => {
          flatList.push(category);
          if (category.children.length > 0) {
            flatList = flatList.concat(flattenCategories(category.children));
          }
        });
        return flatList;
      };

      return flattenCategories(processedCategories);
    },

    async getCategories() {
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog}
      }
      await this.axios.get(this.url('getClientCategories')+'?type=product', options)
        .then((res) => {
        const categories = res.data.objects;
        // Фильтруем категории, оставляя только корневые
        const rootCategories = categories.filter(category => !category.parent);
        this.listCategory = this.processCategories(rootCategories);
        // this.listCategory.unshift({_id: 'all', name: 'All', level: 0});
      });
    },
    getByPrice: _.debounce(function() {
      // console.log("getByPrice debounce call");
      this.getProducts();
    }, 500),

    getCategoryImage(category) {
      // Здесь нужно реализовать логику получения изображения для категории
      // Например, можно добавить поле image в объект категории
      return category.img || '/img/no-news.b3747264.svg';
    },

    scrollCategories(direction) {
      const container = this.$refs.carouselContainer;
      const scrollAmount = 200; // Можно настроить
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  watch:{
    searchText:{
      handler: function (text) {
        if(text.length===0){
          this.searchStatus = false;
          this.getProducts();
        }
      },
    },

    from: {
      handler: function () {
        // console.log("Watche from")
        this.getByPrice();
      },
    },
    to: {
      handler: function () {
        // console.log("Watche to")
        this.getByPrice();
      },
    },
    
    '$route.query.categoryId': {
      immediate: true,
      handler(newCategoryId) {
        if (newCategoryId && newCategoryId !== this.selectedCategory) {
          this.setCategory(newCategoryId);
        }
      }
    }
  },
  created() {
    // Удаляем этот блок, так как теперь мы используем наблюдатель
    // this.selectedCategory = this.route.params.categoryId || null;
    // if (this.selectedCategory) {
    //   // this.loadCategoryProducts(this.selectedCategory);
    // }
  },
  mounted(){

    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
        this.currentPage = parseInt(savedPage);  // Восстанавливем страницу из localStorage
    }

    const savedCategory = localStorage.getItem('currentCategory');
    if (savedCategory) {
        this.selectedCategory = savedCategory;  // Восстанавливаем категорию из localStorage
    }
    //this.getProducts();

    console.log("MOUNTED START")
    this.getProductsForPrice()
    this.rangeSlider()
    // this.getRangeValues()
    this.getCategories()
    // this.getProducts()

    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
}
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
@media (min-width: 1200px) {
  .container-prod {
    max-width: 100%;
    padding-left: 105px;
    padding-right: 120px;
    /* border: 1px solid red */
  }
}
@media (max-width: 570px) {
  .container-prod {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    /* border: 1px solid red */
  }
}
@media ((min-width: 570px) and (max-width: 1200px)) {
  .container-prod {
    max-width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    /* border: 1px solid red */
  }
}

.category-carousel-container {
  position: relative; /* Для позиционирования стрелок относительно этого контейнера */
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}



.show {
  position: absolute !important;
  bottom: 10px;
  width: 93% !important;
  right:3.5%;

}
.mobile-btns{
  display:none;
}
@-webkit-keyframes fadeIn {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);}
}

.backdrop-filter.active{
  display: block;
}
.catalogSearchClearIcon{
  position: absolute;
  top: 12px;
  right: 28px;
}

.catalogSort{
  width: 100%;
  height: 30px;
  outline: none;
  border-style: none;
  padding: 0 16px;
  background: url("../../../assets/img/select_arrow.svg") no-repeat;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-position-x: calc(100% - 15px);
  background-position-y: 7px;
  color: #606877;
  font-size: 14px;
}
.catalogSort:focus-visible{
  border-style: none;
  outline: none;
}
.sortDropDown div{
  cursor: pointer;
}
.sortDropDown div:hover{
  color:#616cf5!important;
}

.sortDropDown{
  position: absolute;
  width: 100%;
  background: #F4F4F4;
  border-radius: 5px;
  top: 35px;
  right: 0;
  padding: 10px;
}

.selectWrapper{
  padding: 0 16px;
  color: #606877;
  font-size: 14px;
  height: 30px;
  border-radius: 20px;
  background: #F4F4F4;
  justify-content: center;
}

.catalogSearchInputIcon{
  position: absolute;
  top: 12px;
  left: 15px;
}
.catalogSearchInput{
  width: calc(100% - 13px);
  font-size: 18px;
  line-height: 24px;
  color: black;
  padding: 10px 120px 10px 40px;
  border-radius: 50px;
  border:1px solid #D3D3D3;
  margin-bottom: 5px;
}

.paginationItem{
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.activePage{
  background-color: #616cf5;
  color: #fff;
  font-weight: bold;
}

.backdrop-filter{
  display: none;
  width: 100%;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  background: #000;
  opacity:0.5;
  z-index:1040;
}
.showCategory.active, .showFilter.active{
  display:block;
  -webkit-animation: fadeIn 0.3s;
  animation-fill-mode:forwards;
}
.showCategory,.showFilter{
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index:9999;
  top: 0;
  right:0;
  display: none;
  transition: .4s;
  background:#fff;
  padding:0 20px;

}

.filter-category{
  font-size: 20px;
  color:#222;
  font-weight: normal;
}
.catalog{
  color:#222222;
  padding: 0;
}
.category-key{
  color:#858585;
}
.category-value{
  color:#616cf5;
}
.save{
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
  position: relative;
}
.list-icon{
  position: absolute;
  left:10px;
  background-size: contain;
}
.new-product, .app-buttons-item{
  height: 40px;
  width:100%;
}
.app-buttons-item{
  justify-content: center;
  color:#616cf5;
  position: relative;
}
.app-buttons-item img{
  position: absolute;
  left: 10px;
}
.catalog-left{
  padding-right:5px;
  /*position: sticky;*/
  top:40px;
  z-index: 1000;
  transition:0.3s;


}
.product-title{
  font-size: 20px;
  font-weight: bold;
}
.product-title img{
  margin-right: 10px;
}

.client-input{
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
}
.label-client{
  color: #8C94A5;
}

.catalog-list{
  line-height: 1;
  box-sizing: border-box;
  list-style-type: none;
  border:none;
  height: 35px;
  color: #8C94A5;
  border-radius: 111.068px;
  padding:0 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.catalog-list:hover{
  /* background: #EBEEFF; */
  color: #616CF5;
  cursor:pointer;
}
.catalog-list.active{
  /* background: #EBEEFF; */
  border-radius: 111.068px;
  color: #616CF5;
}
@media(max-width:481px){
  .catalog-left{
    display:none;
  }
  .mobile-btns{
    display: block;
  }

}

.category-carousel-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}

.category-carousel {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 0;
}

.carousel-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Предотвращаем вертикальный скролл */
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  /* border: 1px solid red; */
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.category-item {
  flex: 0 0 auto;
  width: 167px;
  height: 206px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.category-item.selected {
  border-color: #D3D3D3;
}
.category-item:hover {
  border-color: #D3D3D3;
}

.category-image {
  height: 122px;
  width: 137px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Добавлен отступ снизу */
}

.category-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-name {
  text-align: center;
  font-size: 14px;
  width: 100%;
  height: 40px; /* Фиксированная высота для текста */
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

.carousel-arrow {
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
  /* border: 1px solid red; */
}

.carousel-arrow.left {
  left: 65px;
}

.carousel-arrow.right {
  right: 75px;
}

.all-link-container {
  display: flex;
  align-items: center;
}

.arrow-icon {
  width: 20px;
  height: 20px;
}

.view-all{
  margin-right: 3px;
  font-size: 14px;
  font-weight: 300;
  color: #4A4A4A;
  text-decoration: none;
  font-size: 16px;
}
.view-all:hover{
  color: #3B4DB3;
}


.relatives{
  margin-top: 100px;
  position: absolute;
  width: 100%;
  height: 418px;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  color:#fff;
  border-radius: 15px;
  padding-right: 10%;
}
.main-box{
  height: 520px;
  /*background: url('../../../assets/clients/main-box.svg');*/
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 25px;
  margin-top: -28px;
  border-radius:5px;
}

.details-button {
  background-color: white;
  color: black;
  border: none;

  border-radius: 25px;

  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px; /* Добавлено пространство сверху */

}

.details-button:hover {
  background-color: #f0f0f0; /* Легкое затемнение при наведении */
}

@media (max-width: 768px) {
  .category-carousel-wrapper {
    padding: 0 10px;
  }

  .carousel-arrow {
    display: none;
  }

  .category-item {
    width: 120px;
    height: 160px;
    padding: 10px;
  }

  .category-image {
    height: 90px;
    width: 100px;
    margin-bottom: 5px; /* Уменьшенный отступ для мобильных */
  }

  .category-name {
    font-size: 12px;
    height: 35px; /* Уменьшенная высота для мобильных */
  }
}

</style>